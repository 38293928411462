import React, { useState, useReducer, useEffect,useRef } from "react";
import { makeStyles } from "@material-ui/core/styles";
import GridItem from "components/Grid/GridItem.js";
import GridContainer from "components/Grid/GridContainer.js";
import { Field, reduxForm, change, registerField, getFormValues, reset, untouch } from 'redux-form';
import MaterialTable, { MTableToolbar } from 'material-table';
import CustomInput from "components/CustomInput/CustomInput.js";
import CustomSelect from 'components/CustomSelect/CustomSelect.js'
import Button from "components/CustomButtons/Button.js";
import Accordion from '@material-ui/core/Accordion';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import Typography from '@material-ui/core/Typography';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import CustomRadio from 'components/CustomRadio/CustomRadio'
import Paper from '@material-ui/core/Paper';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction';
import IconButton from '@material-ui/core/IconButton';
import ArrowForwardIosIcon from '@material-ui/icons/ArrowForwardIos';
import CustomDate from 'components/CustomDate/CustomDate'
import AddIcon from '@material-ui/icons/Add';
import Link from '@material-ui/core/Link';
import Divider from '@material-ui/core/Divider';
import { connect, useDispatch, useSelector } from 'react-redux'
import { getProduit } from 'redux/actions/apiActions'
import moment from "moment";
import "moment/locale/fr";
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import ProfilAssure from 'pages/Commercial/components/ProfilAssure'
import ProfilBeneficiaire from 'pages/Commercial/components/ProfilBeneficiaire'
import ProfilSouscipteurInfo from 'pages/Commercial/components/ProfilSouscipteurInfo'
import SouscriptionFicheresume from 'pages/Commercial/components/SouscriptionFicheresume'
import Stepper from '@material-ui/core/Stepper';
import Step from '@material-ui/core/Step';
import StepLabel from '@material-ui/core/StepLabel';
import NavigateNextOutlinedIcon from '@material-ui/icons/NavigateNextOutlined';
import { Grid } from '@material-ui/core'
import DocumentSouscription from 'pages/Commercial/components/DocumentSouscription'
import dayjs from 'dayjs';
import ListItemAvatar from '@material-ui/core/ListItemAvatar';
import Avatar from '@material-ui/core/Avatar';
import ImageIcon from '@material-ui/icons/Image';
import Alert from '@material-ui/lab/Alert';
import LabelledOutline from 'components/LabelledOutline/LabelledOutline'
import CustomPhoneInput from 'components/CustomPhoneInput/CustomPhoneInput'
import {
    addSouscription,
    getPrimesCapital,
    getBanqueAgences,
    getGarantiesAssure,
    getCapitauxGaranies,
    getGarantiesProduit,
    contactsPersonne,
    piecesPersonne
} from "redux/actions/apiActions";
import { getUserData, ageAssure } from 'redux/actions/userActions'
import Snackbar from '@material-ui/core/Snackbar';
import MuiAlert from '@material-ui/lab/Alert';
import PropoTransmission from 'pages/Banking/Propositions/PropoTransmission'
import { handleMoimemeAssure } from 'application'
import SearchClient from 'components/SearchClient/SearchClient'
import ProfilEtatSante from 'pages/Commercial/components/ProfilEtatSante'
import { green } from "@material-ui/core/colors";

import Card from '@material-ui/core/Card';
import CardActionArea from '@material-ui/core/CardActionArea';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import CardMedia from '@material-ui/core/CardMedia';
import StorageIcon from '@material-ui/icons/Storage';
import GridOnIcon from '@material-ui/icons/GridOn';

const forceUpdateReducer = (i) => i + 1

export const useForceUpdate = () => {
    const [, forceUpdate] = useReducer(forceUpdateReducer, 0)
    return forceUpdate
}
function CusAlert(props) {
    return <MuiAlert elevation={6} variant="filled" {...props} />;
}
const customTyles = (theme, styles) => ({
    ...styles,
    cardCategoryWhite: {
        "&,& a,& a:hover,& a:focus": {
            color: "rgba(255,255,255,.62)",
            margin: "0",
            fontSize: "14px",
            marginTop: "0",
            marginBottom: "0"
        },
        "& a,& a:hover,& a:focus": {
            color: "#FFFFFF"
        }
    },
    cardTitleWhite: {
        color: "#FFFFFF",
        marginTop: "0px",
        minHeight: "auto",
        fontWeight: "300",
        fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
        marginBottom: "3px",
        textDecoration: "none",
        "& small": {
            color: "#777",
            fontSize: "65%",
            fontWeight: "400",
            lineHeight: "1"
        }
    },
    cardCategoryWhite: {
        color: "rgba(255,255,255,.62)",
        margin: "0",
        fontSize: "14px",
        marginTop: "0",
        marginBottom: "0"
    },
    cardTitleWhite: {
        color: "#FFFFFF",
        marginTop: "0px",
        minHeight: "auto",
        fontWeight: "300",
        fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
        marginBottom: "3px",
        textDecoration: "none"
    },
    rootExpan: {
        width: '100%',
    },
    heading: {
        fontSize: 26,
        fontWeight: 'bold',
    },
    paper: {
        width: '100%'
    },
    list: {
        width: '100%',
        padding: 5
    },
    containerRoot: {

    },
    buttonAdd: {
        width: '100%',
        marginBottom: 10,
        borderWidth: 2,
        borderStyle: 'dashed',
        borderColor: theme.palette.secondary.main,
        borderRadius: 20,
        '&:hover': {
            borderColor: theme.palette.primary.main,
        }
    },
    buttonSelect: {
        width: '100%',
        marginBottom: 10,
        borderWidth: 2,
        borderStyle: 'dashed',
        borderColor: theme.palette.secondary.main,
        backgroundColor: 'transparent',
        borderRadius: 20,
        '&:hover': {
            borderColor: theme.palette.primary.main,
            backgroundColor: 'transparent',
        }
    },
    cadPrime: {
        borderLefColor: theme.palette.primary.main,
        borderWidth: 10,
        width: '100%',
        marginTop: 20
    },
    buttonPrime: {
        width: '100%',
        marginBottom: 10,
        borderWidth: 2,
        borderColor: theme.palette.secondary.main,
        borderRadius: 20,
        '&:hover': {
            borderColor: theme.palette.primary.main,
        }
    },
    fileZone: {

    },
    buttonAction: {
        marginLeft: 10,
        height: 50
    },
    btnback:{
        display:'flex',
        justifyContent:'center',
        alignItems:'center',
        backgroundColor:"#f9b233",
        paddingRight:20,
        paddingLeft:20,
        paddingTop:10,
        paddingBottom:10,
        color:'#ffffff',
        "&:hover":{
          backgroundColor:"#3c3c3b",
        },
        borderRadius:10,
      },
      btnnext:{
        display:'flex',
        justifyContent:'center',
        alignItems:'center',
        backgroundColor:theme.palette.primary.main,
        paddingRight:20,
        paddingLeft:20,
        paddingTop:10,
        paddingBottom:10,
        color:'#ffffff',
        "&:hover":{
          backgroundColor:"#f9b233",
        },
        borderRadius:10,
      },
      hTrait:{
        marginTop:20,
        marginBottom:20
      },
      bnex:{ 
        backgroundColor: theme.palette.primary.main, 
        height: '100%', 
        color:'white',
        width: '100%',
        "&:hover":{
            backgroundColor: theme.palette.secondary.main, 
        }
    },
    container:{
        width:"75%",
        marginLeft:'auto',
        marginRight:'auto',
        [theme.breakpoints.down('md')]:{
            width:"85%",
        },
        [theme.breakpoints.down('sm')]:{
            width:"100%",
        }
    }

})
const useStyles = makeStyles(customTyles)




function AddProposition(props) {
    
    const dispatch = useDispatch();
    let containerref =useRef()
    const { handleSubmit, user, data, ui: { loading,layoutRoot } } = props;
    //mise à jour des paramètres du compte client
    let produits = useSelector(state => state.api.produits);
    let currentProd = (data && data.hasOwnProperty('produit')) && data.produit
    const [activeStep, setActiveStep] = useState(0)
    const [dataAdherent, setDataAdherent] = useState([])
    const [parcourru, setParcourru] = useState([])
    const [svData, setSvData] = useState(null)
    const [myLoading, setMyLoading] = React.useState(loading)
    const [myErrors, setMyErrors] = useState(null)
    const [mesAssures, setMesAssures] = useState([])
    const [mesGaranties, setMesGaranties] = useState(null)
    const [primeCalculer, setPrimecalculer] = useState(false)
    const [capitaux, setCapitaux] = useState(null)
    const [selectedRow, setSelectedRow] = useState(null)
    const [initAss, setInitAss] = useState(false)
    const [initResume, setInitResume] = useState(false)
    const [showSante,setShowSante] = useState(false)

    const resetAdFields = (formName, fieldsObj) => {
        Object.keys(fieldsObj).forEach(fieldKey => {
            dispatch(change(formName, fieldKey, fieldsObj[fieldKey]));
            //reset the field's error
            dispatch(untouch(formName, fieldKey));
        });
    }
    //gestion alert message
    const [openAlert, setOpenAlert] = React.useState(false);
    const handleOpenAlert = () => {
        setOpenAlert(true);
    };
    const handleCloseAlert = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }
        setOpenAlert(false);
        setMyErrors(null)
    };

    useEffect(() => {
        if (data && data.hasOwnProperty('produit')) {
            props.getGarantiesProduit(data.produit.CodeProduit).then((data) => {
                if (data) {
                    setMesGaranties(data)
                }
            })
        }

    }, [currentProd]);

    //affectation des durée
    const affectationDuree = (dureeCotisationMin, dureeCotisationMax, dureeContratMin, dureeContratMax, optionnelle) => {
        const { updateField } = props

        //durée cotisation mini
        if (!data.hasOwnProperty('dureecontratmin')) {
            props.dispatch(registerField("adForm", 'dureecontratmin', 'Field'));
        } else {
            if (optionnelle && (parseInt(dureeContratMin) > parseInt(data.dureecontratmin))) { dureeContratMin = props.data.dureecontratmin }
        }

        updateField('dureecontratmin', dureeContratMin)

        //durée cotisation maximum
        if (!data.hasOwnProperty('dureecontratmax')) {
            props.dispatch(registerField("adForm", 'dureecontratmax', 'Field'));
        } else {
            if (optionnelle && (parseInt(dureeContratMax) < parseInt(props.data.dureecontratmax))) { dureeContratMax = props.data.dureecontratmax }
        }
        updateField('dureecontratmax', dureeContratMax)
        //duré cotisation mini
        if (!props.data.hasOwnProperty('dureecotisationmin')) {
            props.dispatch(registerField("adForm", 'dureecotisationmin', 'Field'));
        } else {
            if (optionnelle && (parseInt(dureeCotisationMin) > parseInt(props.data.dureecotisationmin))) { dureeCotisationMin = props.data.dureecotisationmin }
        }
        updateField('dureecotisationmin', dureeCotisationMin)
        //durée cotisation max
        if (!props.data.hasOwnProperty('dureecotisationmax')) {
            props.dispatch(registerField("adForm", 'dureecotisationmax', 'Field'));
        } else {
            if (optionnelle && (parseInt(dureeCotisationMax) < parseInt(props.data.dureecotisationmax))) { dureeCotisationMax = props.data.dureecotisationmax }
        }
        updateField('dureecotisationmax', dureeCotisationMax)

    }
    //ajout d'un assurée
    const addAssure = (defaultData, moimeme) => {
        setMyLoading(true)
        const { updateField, getGarantiesAssure } = props
        //vérification de la présence des garanties
        if (mesGaranties && mesGaranties.length === 0) {
            setMyLoading(false)
            setMyErrors({ type: 'error', message: 'Aucune garanties trouvées' })
            handleOpenAlert()
            return false
        }

        //vérification de la présence des information sur l'assurée
        if (!defaultData) {
            setMyLoading(false)
            setMyErrors({ type: 'error', message: 'Erreur sur l\'assurée' })
            handleOpenAlert()
            return false
        }


        //selection des garanties
        let dureeCotisationMin = undefined
        let dureeCotisationMax = undefined
        let dureeContratMin = undefined
        let dureeContratMax = undefined

        let garantiesObligatoires
        let garantiesComplementaires
        let codeproduit
        let dateNaissance
        codeproduit = data.produit.CodeProduit
        dateNaissance = moimeme ? data.dateNaissanceSous : defaultData.dateNaissanceAssure
        //récupération des garanties pour l'assuré
        getGarantiesAssure(codeproduit, ageAssure(dayjs(dateNaissance).format('YYYY-MM-DD'))).then((garanties) => {

            if (garanties) {
                garantiesObligatoires = garanties.filter(item => item.estobligatoire == 1)
                garantiesComplementaires = garanties.filter(item => item.estobligatoire == 0)

                let garantiesParcourru
                defaultData.garantiesFacultatives = []
                if ((garantiesObligatoires && garantiesObligatoires.length !== 0) || (garantiesComplementaires && garantiesComplementaires.length !== 0)) {
                    let result = false
                    garantiesParcourru = garantiesObligatoires.length !== 0 ? garantiesObligatoires : garantiesComplementaires
                    defaultData.garantiesSouscrits = mesGaranties.filter((item) => {
                        result = false
                        garantiesParcourru.forEach(async (element) => {
                            if (element.codeproduitgarantie === item.CodeProduitGarantie) {
                                result = true;
                                item.estunique = element.estunique
                                item.description = element.description
                                item.checked = true
                                item.agemin = element.agemin
                                item.agemax = element.agemax
                                item.dureecotisationmin = element.dureecotisationmin
                                item.dureecotisationmax = element.dureecotisationmax
                                item.dureecontratmin = element.dureecontratmin
                                item.dureecontratmax = element.dureecontratmax
                                item.primemin = element.primemin
                                item.montantgarantie = element.montantgarantie
                                item.type = element.type
                                item.estobligatoire = element.estobligatoire
                                item.estprincipal = element.estprincipal
                                item.branche = element.branche
                                item.capitaux = (element.type === 'Decces') ? (await getPrimesCapital(data.produit.CodeProduit, item.CodeProduitGarantie)) : (await getCapitauxGaranies(data.produit.CodeProduit, item.CodeProduitGarantie))

                                if (dureeCotisationMin !== undefined && element.dureecotisationmin < dureeCotisationMin) {
                                    dureeCotisationMin = element.dureecotisationmin
                                } else {
                                    dureeCotisationMin = element.dureecotisationmin
                                }
                                if (dureeCotisationMax !== undefined && element.dureecotisationmax > dureeCotisationMax) {
                                    dureeCotisationMax = element.dureecotisationmax
                                } else {
                                    dureeCotisationMax = element.dureecotisationmax
                                }
                                if (dureeContratMin !== undefined && element.dureecontratmin < dureeContratMin) {
                                    dureeContratMin = element.dureecontratmin
                                } else {
                                    dureeContratMin = element.dureecontratmin
                                }
                                if (dureeContratMax !== undefined && element.dureecontratmax > dureeContratMax) {
                                    dureeContratMax = element.dureecontratmax
                                } else {
                                    dureeContratMax = element.dureecontratmax
                                }

                                if (element.estunique == 1) {
                                    let garantiesFree = mesGaranties.filter(g => g.CodeProduitGarantie !== item.CodeProduitGarantie)
                                    setMesGaranties(garantiesFree)
                                }
                                if (garantiesObligatoires.length !== 0 && element.estobligatoire === 0) {

                                    defaultData.garantiesFacultatives.push(item)
                                }
                                if (data.produit.CodeProduit === 'YKL_2004' || data.produit.CodeProduit === 'YKV_2004' || data.produit.CodeProduit === 'YKF_2004') {
                                    const maDuree = (70 - parseInt(ageAssure(dayjs(dateNaissance).format('YYYY-MM-DD'))))
                                    if (maDuree) {
                                        dureeCotisationMin = maDuree
                                        dureeCotisationMax = maDuree
                                        dureeContratMin = maDuree
                                        dureeContratMax = maDuree
                                    }
                                }
                                if (data.produit.CodeProduit === 'ASSCPTBNI') {
                                    const maDuree = (66 - parseInt(ageAssure(dayjs(dateNaissance).format('YYYY-MM-DD'))))
                                    if (maDuree) {
                                        dureeCotisationMin = maDuree
                                        dureeCotisationMax = maDuree
                                        dureeContratMin = maDuree
                                        dureeContratMax = maDuree
                                    }
                                }
                                affectationDuree(dureeCotisationMin, dureeCotisationMax, dureeContratMin, dureeContratMax, false)
                                return
                            }
                        });
                        return result
                    })
                    //
                    if (garantiesObligatoires.length !== 0 && garantiesComplementaires.length !== 0) {

                        defaultData.garantiesFacultatives = mesGaranties.filter((item) => {

                            result = false
                            garantiesComplementaires.forEach(async (element) => {
                                if (element.codeproduitgarantie === item.CodeProduitGarantie) {

                                    result = true;
                                    item.checked = false
                                    item.estunique = element.estunique
                                    item.description = element.description
                                    item.agemin = element.agemin
                                    item.agemax = element.agemax
                                    item.dureecotisationmin = element.dureecotisationmin
                                    item.dureecotisationmax = element.dureecotisationmax
                                    item.dureecontratmin = element.dureecontratmin
                                    item.dureecontratmax = element.dureecontratmax
                                    item.dureecontratmax = element.dureecontratmax
                                    item.primemin = element.primemin
                                    item.montantgarantie = element.montantgarantie
                                    item.type = element.type
                                    item.estobligatoire = element.estobligatoire
                                    item.estprincipal = element.estprincipal
                                    item.branche = element.branche
                                    item.capitaux = (element.type === 'Decces') ? (await getPrimesCapital(data.produit.CodeProduit, item.CodeProduitGarantie)) : (await getCapitauxGaranies(data.produit.CodeProduit, item.CodeProduitGarantie))

                                    if (element.estunique == 1) {
                                        let garantiesFree = mesGaranties.filter(g => g.CodeProduitGarantie !== item.CodeProduitGarantie)
                                        setMesGaranties(garantiesFree)

                                    }
                                    return
                                }
                            });
                            return result
                        })
                    }

                    defaultData.monIndex = (mesAssures.length + 1)
                    let newAssure = [...mesAssures, defaultData]
                    props.updateField('assures', newAssure)
                    setMesAssures(newAssure)
                    setMyLoading(false)
                    setActiveStep(activeStep + 2)
                    handleEtpapeParcourru(activeStep + 2)
                } else {
                    setMyLoading(false)
                    setMyErrors({ type: 'error', message: 'Aucune garanties trouvée' })
                    handleOpenAlert()
                    return false
                }
            } else {
                setMyLoading(false)
                setMyErrors({ type: 'error', message: 'Aucune garanties trouvée' })
                handleOpenAlert()
                return false
            }
        }).catch((err) => {
            setMyLoading(false)
            setMyErrors({ type: 'error', message: 'Erreur lors de la récupération des garanties' })
            handleOpenAlert()
            return false
        });
    }

    //Aller à l'étape suivante
    const handleNext = (values) => {
        if (activeStep === 1 && values.produit.CodeProduit === 'PVRBNI') {
            const currentAss = handleMoimemeAssure(data)
            if (currentAss) {
                addAssure(currentAss, true)
            } else {
                setMyErrors({ type: 'error', message: 'Erreur de récupération de l\'assuré' })
                handleOpenAlert()
            }
        } else {
            setActiveStep(activeStep + 1)
            handleEtpapeParcourru(activeStep + 1)
        }
        if ((activeStep + 1) === 2) {
            setInitAss(true)
        } else {
            setInitAss(false)
        }
        if ((!showSante && ((activeStep + 1) === 5)) || (showSante && ((activeStep + 1) === 6))) {
            setInitResume(true)
        } else {
            setInitResume(false)
        }
        containerref.current.offsetParent.scrollTop=0
    };

    //redirection après souscription
    const handleRedirectSouscription = (data) => {
        setActiveStep(0)
        setParcourru([])
        handleEtpapeParcourru(0)
        props.history.push(`/${layoutRoot}/propositions`, { souscription: data });
    }

    const [snOpen, setSnOpen] = React.useState(false);
    const [snMessage, setSnMessage] = React.useState("")
    const handleSnClick = () => {
        setSnOpen(true);
    };

    const handleSnClose = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }
        setSnOpen(false);
    };

    //Aller à étape précedente
    const handleBack = () => {
       
        if (activeStep > 0) {
            setActiveStep(activeStep - 1)
        } else {
            setActiveStep(0)
            handleEtpapeParcourru(0)
        }
        if ((activeStep - 1) === 2) {
            setInitAss(true)
        } else {
            setInitAss(false)
        }
    
        if ( (showSante && ((activeStep - 1) === 6)) || (!showSante && ((activeStep - 1) === 5))) {
            setInitResume(true)
            dispatch({ type: "STOP_LOADING_UI" })
        } else {
            setInitResume(false)
        }
       
        containerref.current.offsetParent.scrollTop=0
    };
  
    //recherche adherent
    const [inputField, setInputField] = useReducer((state, newState) => ({ ...state, ...newState }),
        {
            agence: user.hasOwnProperty('credentials') ? user.credentials.agence : "",
            numerocompte: '',
            rib: '',
        });
    const handleInputChange = (event) => {
        const { name, value } = event.target
        setInputField({
            [name]: value
        })
    }
    const handleSetDataAdherent = () => {
        if (dataAdherent && dataAdherent.length !== 0) {
            const { client, compte } = dataAdherent

            if (!props.data.hasOwnProperty('civiliteSous')) {
                props.dispatch(registerField("adForm", 'civiliteSous', 'Field'));
            }
            if (client && client.civilite && client.civilite !== null) props.updateField('civiliteSous', client.civilite)

            if (!props.data.hasOwnProperty('nomSous')) {
                props.dispatch(registerField("adForm", 'nomSous', 'Field'));
            }
            if (client && client.nom && client.nom !== null) props.updateField('nomSous', client.nom)

            if (!props.data.hasOwnProperty('prenomSous')) {
                props.dispatch(registerField("adForm", 'prenomSous', 'Field'));
            }
            if (client && client.prenom && client.prenom !== null) props.updateField('prenomSous', client.prenom)

            if (!props.data.hasOwnProperty('dateNaissanceSous')) {
                props.dispatch(registerField("adForm", 'dateNaissanceSous', 'Field'));
            }
            if (client && client.datenaissance && client.datenaissance !== null) props.updateField('dateNaissanceSous', client.datenaissance.split('T')[0])

            if (!props.data.hasOwnProperty('lieuNaissanceSous')) {
                props.dispatch(registerField("adForm", 'lieuNaissanceSous', 'Field'));
            }
            if (client && client.lieunaissance && client.lieunaissance !== null) props.updateField('lieuNaissanceSous', client.lieunaissance)

            if (!props.data.hasOwnProperty('sexeSous')) {
                props.dispatch(registerField("adForm", 'sexeSous', 'Field'));
            }
            if (client && client.genre && client.genre !== null) props.updateField('sexeSous', client.genre)

            if (!props.data.hasOwnProperty('pieceIdentiteSous')) {
                props.dispatch(registerField("adForm", 'pieceIdentiteSous', 'Field'));
            }
            if (client && client.numeropiece && client.numeropiece != null) props.updateField('pieceIdentiteSous', client.numeropiece)

            if (!props.data.hasOwnProperty('naturePiece')) {
                props.dispatch(registerField("adForm", 'naturePiece', 'Field'));
            }
            if (client && client.naturePiece && client.naturePiece !== null) props.updateField('naturePiece', client.naturePiece)

            if (!props.data.hasOwnProperty('lieuResidenceSous')) {
                props.dispatch(registerField("adForm", 'lieuResidenceSous', 'Field'));
            }
            if (client && client.lieuresidence && client.lieuresidence !== null) props.updateField('lieuResidenceSous', client.lieuresidence)

            if (!props.data.hasOwnProperty('professionSous')) {
                props.dispatch(registerField("adForm", 'professionSous', 'Field'));
            }
            if (client && client.profession && client.profession !== null) props.updateField('professionSous', client.profession)

            if (!props.data.hasOwnProperty('employeurSous')) {
                props.dispatch(registerField("adForm", 'employeurSous', 'Field'));
            }
            if (client && client.secteuractivite && client.secteuractivite !== null) props.updateField('employeurSous', client.secteuractivite)

            //contact
            if (!props.data.hasOwnProperty('adressepostale')) {
                props.dispatch(registerField("adForm", 'adressepostale', 'Field'));
            }
            if (client && client.adressepostale && client.adressepostale !== null) props.updateField('adressepostale', client.adressepostale)

            if (!props.data.hasOwnProperty('telephoneSous')) {
                props.dispatch(registerField("adForm", 'telephoneSous', 'Field'));
            }
            if (client && client.telephonebureau && client.telephonebureau !== null) props.updateField('telephoneSous', client.telephonebureau)

            if (!props.data.hasOwnProperty('emailSous')) {
                props.dispatch(registerField("adForm", 'emailSous', 'Field'));
            }
            if (client && client.email && client.email !== 'null' && client.email !== null) props.updateField('emailSous', client.email)

            if (!props.data.hasOwnProperty('mobileSous')) {
                props.dispatch(registerField("adForm", 'mobileSous', 'Field'));
            }
            if (client && client.mobile1 && client.mobile1 !== null) props.updateField('mobileSous', `+225${client.mobile1}`)

            if (!props.data.hasOwnProperty('mobile2Sous')) {
                props.dispatch(registerField("adForm", 'mobile2Sous', 'Field'));
            }
            if (client && client.mobile2 && client.mobile2 !== null) props.updateField('mobile2Sous', `+225${client.mobile2}`)


            //compte
            if (!props.data.hasOwnProperty('codebanque')) {
                props.dispatch(registerField("adForm", 'codebanque', 'Field'));
            }
            if (compte && compte.codebanque && compte.codebanque !== null) props.updateField('codebanque', compte.codebanque)

            if (!props.data.hasOwnProperty('codeguichet')) {
                props.dispatch(registerField("adForm", 'codeguichet', 'Field'));
            }
            if (compte && compte.codeguichet && compte.codeguichet !== null) props.updateField('codeguichet', compte.codeguichet)

            if (!props.data.hasOwnProperty('agence')) {
                props.dispatch(registerField("adForm", 'agence', 'Field'));
            }
            if (compte && compte.codeguichet && compte.codeguichet !== null) props.updateField('agence', compte.codeguichet)

            if (!props.data.hasOwnProperty('numCompte')) {
                props.dispatch(registerField("adForm", 'numCompte', 'Field'));
            }
            if (compte && compte.numerocompte && compte.numerocompte !== null) props.updateField('numCompte', compte.numerocompte)

            if (!props.data.hasOwnProperty('rib')) {
                props.dispatch(registerField("adForm", 'rib', 'Field'));
            }
            if (compte && compte.rib && compte.rib !== null) props.updateField('rib', compte.rib)

            if (!props.data.hasOwnProperty('naturecompte')) {
                props.dispatch(registerField("adForm", 'naturecompte', 'Field'));
            }
            if (compte && compte.naturecompte && compte.naturecompte !== null) props.updateField('naturecompte', compte.naturecompte)

        }

    }

    const handlegetProduit = () => {
        if (user.credentials.hasOwnProperty('branche')) {
            dispatch(getProduit(user.credentials.branche))
        }
    };

    useEffect(() => {
        handlegetProduit();
        if (!props.hasOwnProperty('codeagent')) {
            props.dispatch(registerField("adForm", 'codeagent', 'Field'));
        }
        props.updateField('codeagent', user.credentials.codeagent)

        if (!props.hasOwnProperty('nomagent')) {
            props.dispatch(registerField("adForm", 'nomagent', 'Field'));
        }
        props.updateField('nomagent', `${user.credentials.nom} ${user.credentials.prenom}`)

        if (!props.hasOwnProperty('agence')) {
            props.dispatch(registerField("adForm", 'agence', 'Field'));
        }
        props.updateField('agence', user.credentials.agence)

    }, [user]);

    const [resume, setResume] = useState(false)
    const handleResume = () => {
        setResume(!resume)
    }
    const [banqueAgences, setBanqueAgences] = useState(null)
    const handleAgence = async (codebanque) => {
        const mesagences = await getBanqueAgences(codebanque)
        setBanqueAgences(mesagences)
    }
    useEffect(() => {
        document.title = "YNOV - Nouvelle proposition"
    })
    useEffect(() => {
        if (props.location.hasOwnProperty('state')) {
            if (props.location.state && props.location.state.hasOwnProperty('step')) {
                handleEtpapeParcourru(props.location.state.step)
                setActiveStep(props.location.state.step)
            }
        }
    }, [])

    useEffect(() => {
        handleSetDataAdherent()
    }, [dataAdherent])

    const handleEtpapeParcourru = (etape) => {
        const currentIndex = parcourru.findIndex(elt => elt === etape);
        const newParcours = [...parcourru];
        if (currentIndex === -1) {
            newParcours.push(etape);
        }
        setParcourru(newParcours)
    }

    const handleAlerte = (val) => {
        setMesErrors(null)
        setOpenAlert(val)
    }
    //mise à jour des erreurs
    const handleErrors = (errors) => {
        setMesErrors(errors)
        setOpenAlert(true)
    }
    //modale search
    const [open, setOpen] = useState(false)

    const handleClose = () => {
        setOpen(false)
    }

    //gestion des fichiers
    const classes = useStyles();
    const [mesErrors, setMesErrors] = useState(null)
    const [souscripteurData, setSouscripteurData] = useReducer((state, newState) => ({ ...state, ...newState }),
        {
            assures: '',
            beneficiaires: '',
            produitsSous: '',
        });


    //Fitre de la table de produit
    const customFilter = (columFilter, tableFilter, valueSeach) => {
        const isproduit = (item) => {
            let result = false
            for (const key of columFilter) {
                if (item[key]) {
                    if (item[key].toUpperCase().indexOf(valueSeach.toUpperCase()) !== -1) {
                        result = true
                        break;
                    } else {
                        result = false
                    }
                } else {
                    result = false
                }
            }
            return result
        }
        return tableFilter.filter(isproduit)
    }

    const findStep = (ref) => {
        if (parcourru.findIndex(elt => elt === ref) === -1) { return false } else { return true }
    }

    const [produitData, setProduitData] = React.useState({
        columns: [
            {
                title: 'code produit',
                field: 'codeProduit',
                cellStyle: { fontSize: 30, padding: 0, color: '#000', fontWeight: 'bold' },
                render: rowData =>(<List className={classes.list}>
                    <ListItem>
                        <ListItemAvatar>
                            <Avatar variant="square" sizes={50}>
                                <ImageIcon />
                            </Avatar>
                        </ListItemAvatar>
                        <ListItemText primary={`${rowData.MonLibelle} - ${rowData['Formule'] || ""}`} secondary={
                            <React.Fragment>
                                <Typography
                                    component="span"
                                    variant="body2"
                                    className={classes.inline}
                                    color="textPrimary"
                                    display='block'
                                >
                                    {rowData.DureCotisationAns ? `Durée ${rowData.DureCotisationAns}` : ""}
                                </Typography>
                            </React.Fragment>
                        } />
                        <ListItemSecondaryAction>
                            <IconButton onClick={() => handleCheckedProduit(rowData)} className={classes.bnex} edge="end" aria-label="suivant">
                                <ArrowForwardIosIcon />
                            </IconButton>
                        </ListItemSecondaryAction>
                    </ListItem>
                </List>),
            },
            { title: 'Libelle', field: 'MonLibelle', hidden: true },
            { title: 'Durée cotisation', field: 'DureeCotisationAns', type: 'numeric', hidden: true },
            { title: 'Durée souscription', field: 'DureeSouscriptionAnnee', type: 'numeric', hidden: true },
            { title: 'Type', field: 'CodeProduitNature', hidden: true },
        ]
    });



    let mesVilles = useSelector(state => state.api.villes)
    let professions = useSelector(state => state.api.professions)


    const handleClientSelect = (event, myrow) => {
        setSelectedRow(myrow)
        if (!props.hasOwnProperty('produit')) {
            props.dispatch(registerField("adForm", 'produit', 'Field'));
        }
        props.updateField('produit', myrow)
        switch (myrow.CodeProduit) {
            case 'YKE_2008': case 'YKE_2018': case 'LPREVO': case 'YKS_2008': case 'YKS_2018': case 'YKF_2008': case 'YKL_2004': case 'YKV_2004': case 'YKF_2004':
                setPrimecalculer(true)
                getPrimesCapital(myrow.CodeProduit).then((data) => {
                    setCapitaux(data)
                })
                break;
            default:
                setPrimecalculer(false)
                break;
        }
    }
    const handleCheckedProduit = (myrow) => {
        setSelectedRow(myrow)
        if (!props.hasOwnProperty('produit')) {
            props.dispatch(registerField("adForm", 'produit', 'Field'));
        }
        props.updateField('produit', myrow)
        switch (myrow.CodeProduit) {
            case 'YKE_2008': case 'YKE_2018': case 'LPREVO': case 'YKS_2008': case 'YKS_2018': case 'YKF_2008': case 'YKL_2004': case 'YKV_2004': case 'YKF_2004':
                setPrimecalculer(true)
                getPrimesCapital(myrow.CodeProduit).then((data) => {
                    setCapitaux(data)
                })
                break;
            default:
                setPrimecalculer(false)
                break;
        }
        //recheche de client
        handleEtpapeParcourru(1)
        setActiveStep(1)
    }

    //modale search
    const [openSearch, setOpenSearch] = useState(false)

    const handleOpenClientSeach = () => {
        setOpenSearch(true)
    }
    const handleSearchClose = () => {
        setOpenSearch(false)
    }
    const addClientSelect = (mesDonnes) => {
        if (mesDonnes) {
            resetAdFields('adForm', {
                naturePiece: null,
                pieceIdentiteSous: null,
                telephoneSous: null,
                bp: null,
                emailSous: null,
                mobileSous: null,
                mobileSous2: null,
                civiliteSous: null,
                nomSous: null,
                prenomSous: null,
                dateNaissanceSous: null,
                lieuNaissanceSous: null,
                lieuResidenceSous: null,
                professionSous: null,
                numerocompte: null
            })
            setSouscripteurData({
                civiliteSous: mesDonnes.CodeCivilite === '1' ? 'Monsieur' : mesDonnes.CodeCivilite === '2' ? 'Madame' : mesDonnes.CodeCivilite === '3' ? 'Mademoiselle' : 'M',
                nomSous: mesDonnes.Nom,
                prenomSous: mesDonnes.Prenom,
                dateNaissanceSous: moment(mesDonnes.DateNaissance).format('YYYY-MM-DD'),
                lieuNaissanceSous: mesDonnes.LieuNaissance,
                lieuResidenceSous: mesDonnes.LieuResidence,
                professionSous: mesDonnes.Profession,
            })
            //selection pièce administrative
            piecesPersonne({ code: mesDonnes.CodePersonne }).then(pieces => {
                if (pieces) {
                    let typePiece = ""
                    let numPiece = ""
                    let indice
                    if (Array.isArray(pieces)) {
                        if ((indice = pieces.findIndex(item => item.PieceType === 'CNI')) !== -1) {
                            typePiece = 'CNI'
                            numPiece = pieces[indice].NumPiece
                        } else if ((indice = pieces.findIndex(item => item.PieceType === 'PASS')) !== -1) {
                            typePiece = 'Passport'
                            numPiece = pieces[indice].NumPiece
                        } else if ((indice = pieces.findIndex(item => item.PieceType === 'ATT')) !== -1) {
                            typePiece = 'AT'
                            numPiece = pieces[indice].NumPiece
                        }

                        if (!props.data.hasOwnProperty('naturePiece')) {
                            props.dispatch(registerField("adForm", 'naturePiece', 'Field'));
                        }
                        if (typePiece && typePiece !== null) props.updateField('naturePiece', typePiece)

                        if (!props.data.hasOwnProperty('pieceIdentiteSous')) {
                            props.dispatch(registerField("adForm", 'pieceIdentiteSous', 'Field'));
                        }
                        if (numPiece && numPiece !== null) props.updateField('pieceIdentiteSous', numPiece)
                    }
                }
            })
            //selection des contacts
            contactsPersonne({ code: mesDonnes.CodePersonne }).then(res => {
                if (res) {
                    if (Array.isArray(res)) {
                        let oldType = ""
                        res.forEach(contact => {

                            if (contact.CodeTypeContact === 'TELPRO') {
                                oldType = contact.CodeTypeContact
                                if (!props.data.hasOwnProperty('telephoneSous')) {
                                    props.dispatch(registerField("adForm", 'telephoneSous', 'Field'));
                                }
                                if (contact && contact.Contact && contact.Contact !== null) props.updateField('telephoneSous', contact.Contact)
                            }
                            if (contact.CodeTypeContact === 'BP') {
                                oldType = contact.CodeTypeContact
                                if (!props.data.hasOwnProperty('bp')) {
                                    props.dispatch(registerField("adForm", 'bp', 'Field'));
                                }
                                if (contact && contact.Contact && contact.Contact !== null) props.updateField('bp', contact.Contact)
                            }
                            if (contact.CodeTypeContact === 'TEL') {
                                oldType = contact.CodeTypeContact
                                if (!props.data.hasOwnProperty('telephoneSous')) {
                                    props.dispatch(registerField("adForm", 'telephoneSous', 'Field'));
                                }
                                if (contact && contact.Contact && contact.Contact !== null) props.updateField('telephoneSous', contact.Contact)
                            }
                            if (contact.CodeTypeContact === 'EMAIL') {
                                oldType = contact.CodeTypeContact
                                if (!props.data.hasOwnProperty('emailSous')) {
                                    props.dispatch(registerField("adForm", 'emailSous', 'Field'));
                                }
                                if (contact && contact.Contact && contact.Contact !== null) props.updateField('emailSous', contact.Contact)
                            }
                            if (contact.CodeTypeContact === 'CEL') {
                                if (contact.CodeTypeContact !== oldType) {
                                    oldType = contact.CodeTypeContact
                                    if (!props.data.hasOwnProperty('mobileSous')) {
                                        props.dispatch(registerField("adForm", 'mobileSous', 'Field'));
                                    }
                                    if (contact && contact.Contact && contact.Contact !== null) props.updateField('mobileSous', contact.Contact)
                                } else {
                                    oldType = contact.CodeTypeContact
                                    if (!props.data.hasOwnProperty('mobileSous2')) {
                                        props.dispatch(registerField("adForm", 'mobileSous2', 'Field'));
                                    }
                                    if (contact && contact.Contact && contact.Contact !== null) props.updateField('mobileSous2', contact.Contact)
                                }

                            }
                        })
                    }
                }
            })

            //selection lieu de résidence

            if (!props.data.hasOwnProperty('civiliteSous')) {
                props.dispatch(registerField("adForm", 'civiliteSous', 'Field'));
            }

            if (mesDonnes && mesDonnes.CodeCivilite && mesDonnes.CodeCivilite !== null) props.updateField('civiliteSous', mesDonnes.CodeCivilite === '1' ? 'Monsieur' : mesDonnes.CodeCivilite === '2' ? 'Madame' : mesDonnes.CodeCivilite === '3' ? 'Mademoiselle' : 'M')

            if (!props.data.hasOwnProperty('nomSous')) {
                props.dispatch(registerField("adForm", 'nomSous', 'Field'));
            }
            if (mesDonnes && mesDonnes.Nom && mesDonnes.Nom !== null) props.updateField('nomSous', mesDonnes.Nom)

            if (!props.data.hasOwnProperty('prenomSous')) {
                props.dispatch(registerField("adForm", 'prenomSous', 'Field'));
            }
            if (mesDonnes && mesDonnes.Prenom && mesDonnes.Prenom !== null) props.updateField('prenomSous', mesDonnes.Prenom)

            if (!props.data.hasOwnProperty('dateNaissanceSous')) {
                props.dispatch(registerField("adForm", 'dateNaissanceSous', 'Field'));
            }
            if (mesDonnes && mesDonnes.DateNaissance && mesDonnes.DateNaissance !== null) props.updateField('dateNaissanceSous', dayjs(mesDonnes.DateNaissance).format('YYYY-MM-DD'))

            if (!props.data.hasOwnProperty('lieuNaissanceSous')) {
                props.dispatch(registerField("adForm", 'lieuNaissanceSous', 'Field'));
            }
            if (mesDonnes && mesDonnes.LieuNaissance && mesDonnes.LieuNaissance !== null) props.updateField('lieuNaissanceSous', mesDonnes.LieuNaissance)

            if (!props.data.hasOwnProperty('lieuResidenceSous')) {
                props.dispatch(registerField("adForm", 'lieuResidenceSous', 'Field'));
            }
            if (mesDonnes && mesDonnes.LieuResidence && mesDonnes.LieuResidence !== null) props.updateField('lieuResidenceSous', mesDonnes.LieuResidence)

            if (!props.data.hasOwnProperty('professionSous')) {
                props.dispatch(registerField("adForm", 'professionSous', 'Field'));
            }
            if (mesDonnes && mesDonnes.Profession && mesDonnes.Profession !== null) props.updateField('professionSous', mesDonnes.Profession)

            if (!props.data.hasOwnProperty('numCompte')) {
                props.dispatch(registerField("adForm", 'numerocompte', 'Field'));
            }
            if (mesDonnes && mesDonnes.NumCompte && mesDonnes.NumCompte !== null) props.updateField('numCompte', mesDonnes.NumCompte)

        }
        handleSearchClose()

    }

    //Faut-il affciher le questionnaire sur l'etat de santé ?
    useEffect(()=>{
        if(data&&data.hasOwnProperty('produit') && ['PFA_IND','YKF_2008','YKE_2008','YKS_2008','YKS_2018','CADENCE','YKE_2018','CAD_EDUCPLUS','YKR_2021'].includes(data.produit.CodeProduit)){
            setShowSante(true)
        }else{
            setShowSante(false)
        }
    },[currentProd])
    //stepper
    const getSteps = () => {
        return showSante?[
            'Produit',
            'Adhérent',
            'Assuré(e)s',
            'Bénéficiaires',
            'Paiement & périodicité',
            'Etat de santé',
            'Resumé',
            'Documents'
        ]:[
            'Produit',
            'Adhérent',
            'Assuré(e)s',
            'Bénéficiaires',
            'Paiement & périodicité',
            'Resumé',
            'Documents'
        ];
    };

    const renderError = () => (
        <Dialog
            open={openAlert}
            onClose={() => handleAlerte(false)}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
            maxWidth='sm'
            fullWidth
        >
            <DialogTitle id="alert-dialog-title">{"Erreur"}</DialogTitle>
            <DialogContent>
                <DialogContentText id="alert-dialog-description">
                    {mesErrors && <Typography styles={{ color: 'red', fontSize: 14 }}>{mesErrors.general}</Typography>}
                </DialogContentText>
            </DialogContent>
            <DialogActions>
                <Button onClick={() => handleAlerte(false)} color="secondary" autoFocus>
                    OK
                </Button>
            </DialogActions>
        </Dialog>
    )
    const [modeGrid,setModeGrid] = useState(false)
    //affichage liste des produits
    const renderProduitTable = () => (
        <>
        <div className="flex flex-row justify-end items-center">
            <IconButton onProgress={()=>setModeGrid(!modeGrid)}>
                {modeGrid ? <StorageIcon />:<GridOnIcon />}
            </IconButton>
        </div>
        <MaterialTable
            title={null}
            columns={produitData.columns}
            data={query => new Promise((resolve, reject) => {
                const columnSearch = ['CodeProduit', 'MonLibelle', 'DureeCotisationAns', 'DureeSouscriptionAnnee', 'CodeProduitNature']
                resolve({
                    data: customFilter(columnSearch, produits, query.search)
                })
            })}
            onRowClick={((evt, selectedRow) => handleClientSelect(evt, selectedRow))}
            components={{
                Toolbar: props => (
                    <div style={{ backgroundColor: '#e8eaf5' }}>
                        <MTableToolbar {...props} />
                    </div>
                ),
            }}
            localization={{
                toolbar: {
                    searchPlaceholder: 'Trouver un produit',
                    searchTooltip: 'Trouver un produit'
                },
                body: {
                    emptyDataSourceMessage: 'Aucune donnée trouvée !'
                },
                pagination: {
                    labelRowsSelect: 'Ligne(s)',
                    labelDisplayedRows: '{count} sur {from}-{to}',
                    firstTooltip: 'Prémière page',
                    previousTooltip: 'Précédent',
                    nextTooltip: 'Suivant',
                    lastTooltip: 'Dernière page'
                }
            }}
            options={{
                search: true,
                searchFieldAlignment: "left",
                pageSize: 10,
                pageSizeOptions: [10, 20, 30, 40, 50, 100],
                searchFieldStyle: {
                    fontSize: 18,
                    width: '100%',
                    height: 50,
                },
                header: false,
                rowStyle: rowData => ({
                    backgroundColor: (selectedRow && selectedRow.tableData.id === rowData.tableData.id) ? green[50] : '#FFF'
                })
            }}
        />
        </>
    )
    const NouvelleSouscription = () => {
        dispatch(reset('adForm'))
        handleEtpapeParcourru(0)
        setParcourru([])
        setActiveStep(0)
        props.history.push('/com/nouvelle-proposition');
    }

    const renderActionBar = () => {
        return (
            <GridContainer style={{ marginBottom: 20 }} sm={12} xs={12} md={12} lg={12}>
                <GridItem sm={3} xs={3} md={2} lg={2} >
                    <Typography>
                        <Link href="/com/proposition-liste" >
                        ↞ liste
                        </Link>
                    </Typography>
                </GridItem>
                <GridItem sm={6} xs={6} md={9} lg={9} style={{ textAlign: "right" }}>
                    <Button type='reset' onClick={NouvelleSouscription} color="secondary" aria-label="add" variant="contained" disableRipple={false} style={{ padding: 5, marginLeft: 50 }}>
                        <AddIcon size='small' className={classes.extendedIcon} />
                        Nouvelle souscription
                    </Button>
                </GridItem>
            </GridContainer>
        )
    }

    const steps = getSteps()
    const [topen, setTopen] = useState(false)
    const handleTOpen = () => {
        setTopen(true)
    }
    const handleTClose = () => {
        setTopen(false)
    }
    //questionnaire
    let dateAsk
    //data questionnaire
    if(data && data.hasOwnProperty('produit') && ['YKS_2008','YKE_2008','YKS_2018'].includes(data.produit.CodeProduit)){
        dateAsk = [
            { id: 1, nature: "L'assure a t'il été hospitalisé  au cours des ces 3 (trois) derniers mois ?", reponse: '', valeurNature: '', periode: '', lieu: '', details: [] },
            { id: 2, nature: 'Etes-vous sous traitement médical pour, ou souffrez-vous d\'une ces maladies ?', reponse: '', valeurNature: '', periode: '', lieu: '' },
            { id: 3, nature: 'Diabète', reponse: '', valeurNature: '', periode: '', lieu: '', details: ['Suivez-vous un traitement', 'Quel est le type de diabète ?', 'Le diagnostic de cette maladie date de quand ?', 'Votre dernière consultation chez le médecin date de quand ?', 'A combien était la dernière prise'] },
            { id: 4, nature: 'Hypertension artérielle', reponse: '', valeurNature: '', periode: '', lieu: '', details: ['Suivez-vous un traitement ?', 'Le diagnostic de cette maladie date de quand ?', 'Votre dernière consultation chez le médecin date de quand ?', 'A combien était la dernière prise'] },
            { id: 5, nature: 'Drépanocytose', reponse: '', valeurNature: '', periode: '', lieu: '', details: ['Suivez-vous un traitement ?', 'Le diagnostic de cette maladie date de quand ?', 'Votre dernière consultation chez le médecin date de quand ?'] },
            { id: 6, nature: 'Cirrhose de foie', reponse: '', valeurNature: '', periode: '', lieu: '', details: [] },
            { id: 7, nature: 'Affections pulmonaires', reponse: '', valeurNature: '', periode: '', lieu: '', details: ['Suivez-vous un traitement ?', 'Le diagnostic de cette maladie date de quand ?', 'Votre dernière consultation chez le médecin date de quand ?'] },
            { id: 8, nature: 'Cancer', reponse: '', valeurNature: '', periode: '', lieu: '', details: [] },
            { id: 9, nature: 'Anémie', reponse: '', valeurNature: '', periode: '', lieu: '', details: [] },
            { id: 10, nature: 'Insuffisance rénale', reponse: '', valeurNature: '', periode: '', lieu: '', details: [] },
            { id: 11, nature: 'Avez-vous déjà été victime d’un AVC ?', reponse: '', valeurNature: '', periode: '', lieu: '', details: [] },
        ]
    }else if(data && data.hasOwnProperty('produit') && ['YKR_2021'].includes(data.produit.CodeProduit)){
        dateAsk = [
            { id: 1, nature: "Avez-vous ete deja victime d'un accident ?", reponse: '', valeurNature: '', periode: '', lieu: '', details: ["Quelle est la nature de l'accident ?", "Date de l'accident ?", "Lieu de l'accident"] },
            { id: 2, nature: "Suivez-vous ou avez-vous suivi un traitement medical au cours des 6(six) derniers mois", reponse: '', valeurNature: '', periode: '', lieu: '', details: [] },
            { id: 3, nature: "Avez-vous subi deja une transfusion de sang?", reponse: '', valeurNature: '', periode: '', lieu: '', details: [] },
            { id: 4, nature: "Avez-vous fait recemment l'objet d'un test de depistage de l'hepatite B ?", reponse: '', valeurNature: '', periode: '', lieu: '', details: [] },
            { id: 5, nature: "Avez-vous subi deja des interventions chirurgicales ?", reponse: '', valeurNature: '', periode: '', lieu: '', details: [] },
            { id: 6, nature: "Devez-vous subir une intervention chirurgicale ?", reponse: '', valeurNature: '', periode: '', lieu: '', details: [] },
            { id: 7, nature: 'Etes-vous sous traitement médical pour, ou souffrez-vous d\'une ces maladies ?', reponse: '', valeurNature: '', periode: '', lieu: '' },
            { id: 8, nature: 'Diabète', reponse: '', valeurNature: '', periode: '', lieu: '', details: ['Suivez-vous un traitement', 'Quel est le type de diabète ?', 'Le diagnostic de cette maladie date de quand ?', 'Votre dernière consultation chez le médecin date de quand ?', 'A combien était la dernière prise'] },
            { id: 9, nature: 'Hypertension artérielle', reponse: '', valeurNature: '', periode: '', lieu: '', details: ['Suivez-vous un traitement ?', 'Le diagnostic de cette maladie date de quand ?', 'Votre dernière consultation chez le médecin date de quand ?', 'A combien était la dernière prise'] },
            { id: 10, nature: 'Drépanocytose', reponse: '', valeurNature: '', periode: '', lieu: '', details: ['Suivez-vous un traitement ?', 'Le diagnostic de cette maladie date de quand ?', 'Votre dernière consultation chez le médecin date de quand ?'] },
            { id: 11, nature: 'Cirrhose de foie', reponse: '', valeurNature: '', periode: '', lieu: '', details: [] },
            { id: 12, nature: 'Affections pulmonaires', reponse: '', valeurNature: '', periode: '', lieu: '', details: ['Suivez-vous un traitement ?', 'Le diagnostic de cette maladie date de quand ?', 'Votre dernière consultation chez le médecin date de quand ?'] },
            { id: 13, nature: 'Cancer', reponse: '', valeurNature: '', periode: '', lieu: '', details: [] },
            { id: 14, nature: 'Anémie', reponse: '', valeurNature: '', periode: '', lieu: '', details: [] },
            { id: 15, nature: 'Insuffisance rénale', reponse: '', valeurNature: '', periode: '', lieu: '', details: [] },
            { id: 16, nature: 'Avez-vous déjà été victime d’un AVC ?', reponse: '', valeurNature: '', periode: '', lieu: '', details: [] },
        ]
    }else if(data && data.hasOwnProperty('produit') &&  ['CADENCE','PFA_IND','PFA_COL','CAD_EDUCPLUS'].includes(data.produit.CodeProduit)){
        dateAsk = [
            { id: 1, nature: 'Avez-vous déjà été victime d\'un accident', reponse: '', valeurNature: '', periode: '', lieu: '', details: ["Quelle est la nature de l'accident ?", "Date de l'accident ?", "Lieu de l'accident"] },
            { id: 2, nature: 'Suivez-vous ou avez-vous suivi un traitement médical ces 6(six) derniers mois ?', reponse: '', valeurNature: '', periode: '', lieu: '', details: ['Quelle est la nature du traitement ?', 'Date du traitement ?', 'Lieu du traitement'] },
            { id: 3, nature: 'Avez-vous déjà subi une transfusion de sang?', reponse: '', valeurNature: '', periode: '', lieu: '', details: ["Avez-vous fait récemment l\'objet d\'un test de dépistage de l\'hépatite B?"] },
            { id: 4, nature: 'Avez-vous déjà subi des interventions chirurgicales?', reponse: '', valeurNature: '', periode: '', lieu: '', details: [] },
            { id: 5, nature: 'Devez-vous subir une intervention chirurgicale?', reponse: '', valeurNature: '', periode: '', lieu: '', details: ['Quelle est la nature de l\'intervention ?', 'Date de l\'intervention', 'Lieu de l\'intervention'] },
            { id: 6, nature: 'Etes-vous sous traitement médical pour, ou souffrez-vous d\'une ces maladies ?', reponse: '', valeurNature: '', periode: '', lieu: '' },
            { id: 7, nature: 'Diabète', reponse: '', valeurNature: '', periode: '', lieu: '', details: ['Suivez-vous un traitement', 'Quel est le type de diabète ?', 'Le diagnostic de cette maladie date de quand ?', 'Votre dernière consultation chez le médecin date de quand ?', 'A combien était la dernière prise'] },
            { id: 8, nature: 'Hypertension artérielle', reponse: '', valeurNature: '', periode: '', lieu: '', details: ['Suivez-vous un traitement ?', 'Le diagnostic de cette maladie date de quand ?', 'Votre dernière consultation chez le médecin date de quand ?', 'A combien était la dernière prise'] },
            { id: 9, nature: 'Drépanocytose', reponse: '', valeurNature: '', periode: '', lieu: '', details: ['Suivez-vous un traitement ?', 'Le diagnostic de cette maladie date de quand ?', 'Votre dernière consultation chez le médecin date de quand ?'] },
            { id: 10, nature: 'Cirrhose de foie', reponse: '', valeurNature: '', periode: '', lieu: '', details: [] },
            { id: 11, nature: 'Affections pulmonaires', reponse: '', valeurNature: '', periode: '', lieu: '', details: ['Suivez-vous un traitement ?', 'Le diagnostic de cette maladie date de quand ?', 'Votre dernière consultation chez le médecin date de quand ?'] },
            { id: 12, nature: 'Cancer', reponse: '', valeurNature: '', periode: '', lieu: '', details: [] },
            { id: 13, nature: 'Anémie', reponse: '', valeurNature: '', periode: '', lieu: '', details: [] },
            { id: 14, nature: 'Insuffisance rénale', reponse: '', valeurNature: '', periode: '', lieu: '', details: [] },
            { id: 15, nature: 'Avez-vous déjà été victime d’un AVC ?', reponse: '', valeurNature: '', periode: '', lieu: '', details: [] }, 
        ]
    }else{
        dateAsk=[]
    }
    

    const questionnairesField = {
        columns: [
            { title: '#', field: 'id', hidden: true },
            { title: 'Nature', field: 'nature' },
            {
                title: 'Réponse', field: 'reponse', render: rowData => (
                    <Field
                        component={CustomRadio}
                        id={`nature-${rowData.id}`}
                        name={`nature-${rowData.id}`}
                        formControlProps={{
                            fullWidth: true
                        }}
                        options={[{ "label": "Oui", "value": "Oui" }, { "label": "Non", "value": "Non" }]}
                        row
                    />
                )
            },
            {
                title: 'Précisez la nature', field: 'valeurNature', render: rowData => (
                    <Field
                        component={CustomInput}
                        id={`valeurNature-${rowData.id}`}
                        name={`valeurNature-${rowData.id}`}
                        formControlProps={{
                            fullWidth: false
                        }}
                        variant="outlined"
                    />
                )
            },
            {
                title: 'Date ou période de traitement', field: 'periode', render: rowData => (
                    <Field
                        component={CustomInput}
                        id={`periodetrt${rowData.id}`}
                        name={`periodetrt${rowData.id}`}
                        formControlProps={{
                            fullWidth: true
                        }}
                        type='text'
                        variant="outlined"
                    />
                )
            },
            {
                title: 'Lieu de traitement', field: 'lieu', render: rowData => (
                    <Field
                        component={CustomInput}
                        id={`lieu-${rowData.id}`}
                        name={`lieu-${rowData.id}`}
                        formControlProps={{
                            fullWidth: false
                        }}
                        variant='outlined'
                    />
                )
            },
        ], data:dateAsk

    }

    return (
        <div ref={containerref}>
            <Snackbar open={snOpen} autoHideDuration={6000} onClose={handleSnClose}>
                <CusAlert onClose={handleSnClose} severity="error">
                    {snMessage}
                </CusAlert>
            </Snackbar>

            <div>
                <div style={{display:'flex',flexDirection:'column'}}>
                    {(activeStep > 0) ? (
                        <div className="">
                            {renderActionBar()}
                            <div className={classes.core}>
                                {/* <Paper className={classes.paper}> */}
                                    <Stepper activeStep={activeStep} alternativeLabel className="row">
                                        {steps.map(label => (
                                            <Step key={label} className="col">
                                                <StepLabel color='primary'>{label}</StepLabel>
                                            </Step>
                                        ))}
                                    </Stepper>
                                    <Divider component="hr" className={classes.hTrait} />
                                {/* </Paper> */}
                            </div>
                           
                            <div style={{ marginTop: 10 }}> 
                                <Alert variant="filled" severity="warning">Tous les champs marqués d'un " * " sont obligatoires</Alert>
                            </div>
                            {renderError()}

                            {((showSante && activeStep !== 6) || (!showSante && activeStep !== 5))? (
                                <div className={classes.container}>
                                    <Accordion
                                        className={classes.rootExpan}
                                        style={{ marginTop: 20 }}
                                        disabled={!findStep(1)}
                                        expanded={activeStep === 1}
                                    >
                                        <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls="panel1a-content" id="panel1a-header">
                                            <Typography className={classes.heading}>ADHERENT</Typography>
                                        </AccordionSummary>
                                        <AccordionDetails>
                                            <Paper elevation={0} className={classes.paper}>
                                                <form onSubmit={handleSubmit(handleNext)}>
                                                    <GridContainer container className={classes.containerRoot} >
                                                        <GridItem xs={12} sm={12} md={12} lg={12}>
                                                            <LabelledOutline label="RECHERCHER UN CLIENT/PROPSPECT">
                                                                <Button size='large' variant='outlined' className={classes.buttonSelect} color="inherit" onClick={handleOpenClientSeach}>
                                                                    <Typography color='primary'>Selectionnez un client / prospect</Typography>
                                                                </Button>
                                                            </LabelledOutline>
                                                            <SearchClient open={openSearch} mode={user.credentials.branche} handleClose={handleSearchClose} addClientSelect={addClientSelect} />
                                                            <Divider orientation="horizontal" style={{ marginBottom: 20, marginTop: 20 }} />
                                                        </GridItem>

                                                        <GridItem xs={12} sm={12} md={12} lg={12}>

                                                            <GridContainer>
                                                                <GridItem xs={12} sm={12} md={12}>
                                                                    <Field
                                                                        required
                                                                        label="Civilité "
                                                                        id="civiliteSous"
                                                                        name="civiliteSous"
                                                                        options={[{ "label": "Madame", "value": "Madame" }, { "label": "Mademoiselle", "value": "Mademoiselle" }, { "label": "Monsieur", "value": "Monsieur" }]}
                                                                        formControlProps={{
                                                                            fullWidth: true
                                                                        }}
                                                                        row
                                                                        component={CustomRadio}
                                                                    />
                                                                </GridItem>

                                                            </GridContainer>
                                                            <GridContainer>
                                                                <GridItem xs={12} sm={12} md={5}>
                                                                    <Field

                                                                        required
                                                                        label="Nom"
                                                                        id="nomSous"
                                                                        name="nomSous"
                                                                        variant="outlined"
                                                                        formControlProps={{
                                                                            fullWidth: true
                                                                        }}
                                                                        component={CustomInput}
                                                                        autoFocus
                                                                    />
                                                                </GridItem>

                                                                <GridItem xs={12} sm={12} md={7}>
                                                                    <Field

                                                                        required
                                                                        label="Prénom"
                                                                        id="prenomSous"
                                                                        name="prenomSous"
                                                                        variant="outlined"
                                                                        formControlProps={{
                                                                            fullWidth: true
                                                                        }}
                                                                        component={CustomInput}

                                                                    />
                                                                </GridItem>
                                                            </GridContainer>
                                                            <GridContainer>
                                                            
                                                                <GridItem xs={12} sm={12} md={5}>
                                                                {data.produit.CodeProduit === 'ASSCPTBNI' && (

                                                                    <Field
                                                                        required
                                                                        label="Date de naissance"
                                                                        id="dateNaissanceSous"
                                                                        name="dateNaissanceSous"
                                                                        formControlProps={{
                                                                            fullWidth: true,
                                                                        }}
                                                                        InputProps={{
                                                                            type: 'date',
                                                                            inputProps: { max: dayjs().add(-1, 'year').format('YYYY-MM-DD'), min: dayjs().add(-65, 'year').format('YYYY-MM-DD') },
                                                                        }}
                                                                        component={CustomDate}
                                                                        variant="outlined"
                                                                    />) ? (
                                                                        <Field
                                                                        required
                                                                        label="Date de naissance"
                                                                        id="dateNaissanceSous"
                                                                        name="dateNaissanceSous"
                                                                        formControlProps={{
                                                                            fullWidth: true,
                                                                        }}
                                                                        InputProps={{
                                                                            type: 'date',
                                                                            inputProps: { max: dayjs().add(-1, 'year').format('YYYY-MM-DD'), min: dayjs().add(-65, 'year').format('YYYY-MM-DD') },
                                                                        }}
                                                                        component={CustomDate}
                                                                        variant="outlined"
                                                                    />
                                                                    ) : (
                                                                        <Field
                                                                        required
                                                                        label="Date de naissance"
                                                                        id="dateNaissanceSous"
                                                                        name="dateNaissanceSous"
                                                                        formControlProps={{
                                                                            fullWidth: true,
                                                                        }}
                                                                        InputProps={{
                                                                            type: 'date',
                                                                            inputProps: { max: dayjs().add(-18, 'year').format('YYYY-MM-DD'), min: dayjs().add(-100, 'year').format('YYYY-MM-DD') },
                                                                        }}
                                                                        component={CustomDate}
                                                                        variant="outlined"
                                                                    />
                                                                    )}
                                                                </GridItem>

                                                                {/* <GridItem xs={12} sm={12} md={5}>

                                                                    <Field
                                                                        required
                                                                        label="Date de naissance"
                                                                        id="dateNaissanceSous"
                                                                        name="dateNaissanceSous"
                                                                        formControlProps={{
                                                                            fullWidth: true,
                                                                        }}
                                                                        InputProps={{
                                                                            type: 'date',
                                                                            inputProps: { max: dayjs().add(-18, 'year').format('YYYY-MM-DD'), min: dayjs().add(-100, 'year').format('YYYY-MM-DD') },
                                                                        }}
                                                                        component={CustomDate}
                                                                        variant="outlined"
                                                                    />
                                                                </GridItem> */}

                                                                <GridItem xs={12} sm={12} md={7}>
                                                                    <Field

                                                                        label="Lieu de naissance"
                                                                        id="lieuNaissanceSous"
                                                                        name="lieuNaissanceSous"
                                                                        formControlProps={{
                                                                            fullWidth: true
                                                                        }}
                                                                        component={CustomSelect}
                                                                        options={mesVilles}
                                                                        variant="outlined"

                                                                    />
                                                                </GridItem>
                                                            </GridContainer>
                                                            <GridContainer>
                                                                <GridItem xs={12} sm={12} md={5}>
                                                                    <Field
                                                                        required
                                                                        label="N° pièce d'identité"
                                                                        id="pieceIdentiteSous"
                                                                        name="pieceIdentiteSous"
                                                                        variant="outlined"
                                                                        formControlProps={{
                                                                            fullWidth: true
                                                                        }}
                                                                        component={CustomInput}
                                                                        autoFocus

                                                                    />

                                                                </GridItem>
                                                                <GridItem xs={12} sm={12} md={7}>
                                                                    <Field

                                                                        label="Nature de la pièce"
                                                                        id="naturePiece"
                                                                        name="naturePiece"
                                                                        options={[{ "label": "Pièce d'identité", "value": "CNI" }, { "label": "Attestation", "value": "AT" }, { "label": "Passport", "value": "Passport" }]}
                                                                        formControlProps={{
                                                                            fullWidth: true
                                                                        }}
                                                                        component={CustomRadio}
                                                                        row
                                                                        autoFocus

                                                                    />

                                                                </GridItem>
                                                            </GridContainer>
                                                            <GridContainer>
                                                                <GridItem xs={12} sm={12} md={6}>
                                                                    <Field
                                                                        label="Lieu de résidence"
                                                                        id="lieuResidenceSous"
                                                                        name="lieuResidenceSous"
                                                                        formControlProps={{
                                                                            fullWidth: true
                                                                        }}
                                                                        component={CustomSelect}
                                                                        options={mesVilles}
                                                                        variant="outlined"
                                                                    />

                                                                </GridItem>
                                                            </GridContainer>
                                                            <GridContainer>
                                                                <GridItem xs={12} sm={12} md={6}>
                                                                    <Field

                                                                        label="Profession"
                                                                        id="professionSous"
                                                                        name="professionSous"
                                                                        formControlProps={{
                                                                            fullWidth: true
                                                                        }}
                                                                        component={CustomSelect}
                                                                        options={professions}

                                                                    />
                                                                </GridItem>
                                                                <GridItem xs={12} sm={12} md={6}>
                                                                    <Field
                                                                        label="Secteur d'activité"
                                                                        id="employeurSous"
                                                                        name="employeurSous"
                                                                        formControlProps={{
                                                                            fullWidth: true
                                                                        }}

                                                                        options={secteurActivites}
                                                                        component={CustomSelect}

                                                                    />
                                                                </GridItem>
                                                            </GridContainer>

                                                        </GridItem>

                                                    </GridContainer>
                                                    <GridContainer>
                                                        <GridItem xs={12} sm={12} md={12} lg={12} style={{ borderWidthLeft: 1, borderLefColor: 'secondary', }}>
                                                            <GridContainer>
                                                            <GridItem xs={12} sm={12} md={5}>
                                                                {data.produit.CodeProduit === 'ASSCPTBNI' && (

                                                                    <Field
                                                                        label="email"
                                                                        id="emailSous"
                                                                        name="emailSous"
                                                                        variant="outlined"
                                                                        component={CustomInput}
                                                                        formControlProps={{
                                                                            fullWidth: true
                                                                        }}
                                                                        type='email'
                                                                    />) ? (
                                                                        <Field
                                                                        label="email"
                                                                        id="emailSous"
                                                                        name="emailSous"
                                                                        variant="outlined"
                                                                        component={CustomInput}
                                                                        formControlProps={{
                                                                            fullWidth: true
                                                                        }}
                                                                        type='email'
                                                                    />
                                                                    ) : (
                                                                        <Field
                                                                        required
                                                                        label="email"
                                                                        id="emailSous"
                                                                        name="emailSous"
                                                                        variant="outlined"
                                                                        component={CustomInput}
                                                                        formControlProps={{
                                                                            fullWidth: true
                                                                        }}
                                                                        type='email'
                                                                    />
                                                                    )}
                                                                </GridItem>
                                                                {/* <GridItem xs={12} sm={12} md={6}>
                                                                    
                                                                    <Field
                                                                        required
                                                                        label="email"
                                                                        id="emailSous"
                                                                        name="emailSous"
                                                                        variant="outlined"
                                                                        component={CustomInput}
                                                                        formControlProps={{
                                                                            fullWidth: true
                                                                        }}
                                                                        type='email'
                                                                    />

                                                                </GridItem> */}
                                                            </GridContainer>
                                                            <GridContainer>
                                                                <GridItem xs={12} sm={3} md={3} lg={3}>
                                                                    <Field
                                                                        required
                                                                        label="Mobile 1"
                                                                        id="mobileSous"
                                                                        name="mobileSous"
                                                                        variant="outlined"
                                                                        component={CustomPhoneInput}
                                                                        formControlProps={{
                                                                            fullWidth: true
                                                                        }}

                                                                    />

                                                                </GridItem>
                                                                <GridItem xs={12} sm={3} md={3} lg={3}>
                                                                    <Field
                                                                        label="Mobile 2"
                                                                        id="mobile2Sous"
                                                                        name="mobile2Sous"
                                                                        variant="outlined"
                                                                        component={CustomPhoneInput}
                                                                        formControlProps={{
                                                                            fullWidth: true
                                                                        }}
                                                                    />
                                                                </GridItem>
                                                                <GridItem xs={12} sm={3} md={3} lg={3}>
                                                                    <Field
                                                                        label="Téléphone"
                                                                        id="telephoneSous"
                                                                        name="telephoneSous"
                                                                        variant="outlined"
                                                                        component={CustomPhoneInput}
                                                                        formControlProps={{
                                                                            fullWidth: true
                                                                        }}

                                                                    />

                                                                </GridItem>


                                                            </GridContainer>
                                                            <GridContainer>
                                                                <GridItem xs={12} sm={12} md={12}>
                                                                    <LabelledOutline label="Personnes à contacter en cas de besoins">
                                                                        <GridContainer>
                                                                            <GridItem xs={12} sm={12} md={6}>
                                                                                <Field
                                                                                    label="Nom & prénom"
                                                                                    id="personneressource"
                                                                                    name="personneressource"
                                                                                    variant="outlined"
                                                                                    formControlProps={{
                                                                                        fullWidth: true
                                                                                    }}
                                                                                    component={CustomInput}

                                                                                />
                                                                            </GridItem>
                                                                            <GridItem xs={12} sm={12} md={3} lg={3}>
                                                                                <Field
                                                                                    label="Contact"
                                                                                    id="contactpersonneressource"
                                                                                    name="contactpersonneressource"
                                                                                    variant="outlined"
                                                                                    formControlProps={{
                                                                                        fullWidth: true
                                                                                    }}
                                                                                    component={CustomPhoneInput}

                                                                                />
                                                                            </GridItem>
                                                                        </GridContainer>
                                                                        <GridContainer>
                                                                            <GridItem xs={12} sm={12} md={6}>
                                                                                <Field
                                                                                    label="Nom & prénom"
                                                                                    id="personneressource2"
                                                                                    name="personneressource2"
                                                                                    variant="outlined"
                                                                                    formControlProps={{
                                                                                        fullWidth: true
                                                                                    }}
                                                                                    component={CustomInput}

                                                                                />
                                                                            </GridItem>
                                                                            <GridItem xs={12} sm={12} md={3} lg={3}>
                                                                                <Field
                                                                                    label="Contact"
                                                                                    id="contactpersonneressource2"
                                                                                    name="contactpersonneressource2"
                                                                                    variant="outlined"
                                                                                    formControlProps={{
                                                                                        fullWidth: true
                                                                                    }}
                                                                                    component={CustomPhoneInput}

                                                                                />
                                                                            </GridItem>
                                                                        </GridContainer>
                                                                    </LabelledOutline>
                                                                </GridItem>


                                                            </GridContainer>
                                                        </GridItem>
                                                    </GridContainer>
                                                    <Divider component="hr" className={classes.hTrait} />


                                                    <Grid container direction="row" justify="space-between" alignItems="center">

                                                        <Button type="button" className={classes.btnback} onClick={handleBack}>
                                                            Retour
                                                        </Button>
                                                        <Button type='submit' className={classes.btnnext}>
                                                            Continuer
                                                            <NavigateNextOutlinedIcon />
                                                        </Button>

                                                    </Grid>
                                                </form>
                                            </Paper>

                                        </AccordionDetails>
                                    </Accordion>
                                    <Accordion disabled={!findStep(2)} expanded={activeStep === 2}>
                                        <AccordionSummary
                                            expandIcon={<ExpandMoreIcon />}
                                            aria-controls="panel2a-content"
                                            id="panel2a-header"

                                        >
                                            <Typography className={classes.heading}>ASSURE(E)S</Typography>
                                        </AccordionSummary>
                                        <AccordionDetails>
                                            <Paper elevation={0} className={classes.paper}>
                                                {findStep(2) && <ProfilAssure handleBack={handleBack} step={activeStep} hBack={handleBack} initAss={initAss} onSubmit={handleNext} btnshow={true} prodGaranties={mesGaranties ? [...mesGaranties] : []} />}

                                            </Paper>
                                        </AccordionDetails>
                                    </Accordion>

                                    <Accordion disabled={!findStep(3)} expanded={activeStep === 3}>
                                        <AccordionSummary
                                            expandIcon={<ExpandMoreIcon />}
                                            aria-controls="panel3a-content"
                                            id="panel3a-header"

                                        >
                                            <Typography className={classes.heading}>BENEFICIAIRES</Typography>
                                        </AccordionSummary>

                                        <AccordionDetails>
                                            <Paper elevation={0} className={classes.paper}>
                                                {findStep(3) && <ProfilBeneficiaire handleBack={handleBack} step={activeStep} hBack={handleBack} onSubmit={handleNext} btnshow={true} />}
                                            </Paper>
                                        </AccordionDetails>
                                    </Accordion>

                                    <Accordion disabled={!findStep(4)} expanded={activeStep === 4}>
                                        <AccordionSummary
                                            expandIcon={<ExpandMoreIcon />}
                                            aria-controls="panel3a-content"
                                            id="panel3a-header"
                                        >
                                            <Typography className={classes.heading}>CONDITIONS DE PAIEMENT DE LA PRIME & PERIODICITE</Typography>
                                        </AccordionSummary>

                                        <AccordionDetails>
                                            <Paper elevation={0} className={classes.paper}>
                                                <GridContainer>
                                                    {findStep(4) && <ProfilSouscipteurInfo handleResume={handleResume} handleBack={handleBack} step={activeStep} hBack={handleBack} onSubmit={handleNext} btnshow={true} />}
                                                </GridContainer>
                                            </Paper>
                                        </AccordionDetails>
                                    </Accordion>

                                    
                                    
                                    {showSante&&
                                    <Accordion disabled={!findStep(5)} expanded={activeStep === 5}>
                                        <AccordionSummary
                                            expandIcon={<ExpandMoreIcon />}
                                            aria-controls="panel3a-content"
                                            id="panel3a-header"
                                        >
                                            <Typography className={classes.heading}>ETAT DE SANTE DE l'ASSURE</Typography>
                                        </AccordionSummary>

                                        <AccordionDetails>
                                            <Paper elevation={0} className={classes.paper}>
                                                <GridContainer>
                                                    {findStep(5) && <ProfilEtatSante 
                                                    handleResume={handleResume} 
                                                    handleBack={handleBack} 
                                                    step={activeStep} 
                                                    hBack={handleBack} 
                                                    onSubmit={handleNext} 
                                                    btnshow={true} 
                                                    questionaires={questionnairesField.data}
                                                    />}
                                                </GridContainer>

                                            </Paper>
                                        </AccordionDetails>
                                    </Accordion>}

                                    <Accordion disabled={!findStep(showSante?7:6)} expanded={activeStep === (showSante?7:6)}>
                                        <AccordionSummary
                                            expandIcon={<ExpandMoreIcon />}
                                            aria-controls="panel3a-content"
                                            id="panel3a-header"

                                        >
                                            <Typography className={classes.heading}>DOCUMENTS</Typography>
                                        </AccordionSummary>

                                        <AccordionDetails>
                                            <DocumentSouscription 
                                                step={activeStep} 
                                                svdata={svData} 
                                                handleTOpen={handleTOpen} 
                                                handleRedirectSouscription={handleRedirectSouscription} 
                                                handleBack={handleBack} 
                                                onSubmit={handleNext} 
                                                btnshow={true} 
                                            />
                                        </AccordionDetails>
                                    </Accordion>
                                </div>
                            ) : (
                                <GridContainer>
                                    <GridItem xs={12} sm={12} md={12} lg={12}>
                                        <SouscriptionFicheresume 
                                                step={activeStep} 
                                                initResume={initResume} 
                                                setSvData={setSvData} 
                                                handleResume={handleResume} 
                                                handleBack={handleBack} 
                                                handleNext={handleNext} 
                                                onSubmit={handleSubmit} 
                                                btnshow={true} 
                                                handleRedirectSouscription={handleRedirectSouscription} 
                                                questionaires={questionnairesField.data}
                                        />
                                    </GridItem>
                                </GridContainer>

                            )}

                        </div>

                    ) :
                        (
                            <GridContainer>
                                <GridItem xs={12} sm={12} md={12} lg={12}>
                                    <div style={{ borderWidth: 2, borderStyle: 'dashed', marginBottom: 20 }}>
                                        <Typography style={{ textAlign: 'center' }} variant='h5' component='h4' color='primary'>Veuillez selectionner un produit pour demarrer la souscription</Typography>
                                    </div>

                                </GridItem>
                                <GridItem xs={12} sm={12} md={12} lg={12}>
                                    {renderProduitTable()}
                                </GridItem>
                            </GridContainer>
                        )
                    }
                </div>
                {(setSvData && topen) && <PropoTransmission step={activeStep} open={topen} handleClose={handleTClose} data={svData} handleRedirectSouscription={handleRedirectSouscription} handleBack={handleBack} btnshow={true} />}
            </div>
        </div>
    )
}

const updateField = (field, data) => (dispatch) => dispatch(change('adForm', field, data))
const mapPropsActions = {
    addSouscription,
    updateField,
    getUserData,
    getGarantiesAssure,
    getGarantiesProduit
}
const mapStateToProps = (state) => ({
    api: state.api,
    user: state.user,
    data: getFormValues('adForm')(state),
    ui: state.UI
});

export default connect(mapStateToProps, mapPropsActions)(reduxForm({
    form: 'adForm', // a unique identifier for this form
    destroyOnUnmount: false,
})(AddProposition))

const secteurActivites = [
    { "MonLibelle": 'Agroalimentaire' },
    { "MonLibelle": 'Banque / Assurance' },
    { "MonLibelle": 'Bois / Papier / Carton / Imprimerie' },
    { "MonLibelle": 'BTP / Matériaux de construction' },
    { "MonLibelle": 'Édition / Communication / Multimédia' },
    { "MonLibelle": 'Électronique / Électricité' },
    { "MonLibelle": 'Études et conseils' },
    { "MonLibelle": 'Industrie pharmaceutique' },
    { "MonLibelle": 'Informatique / Télécoms' },
    { "MonLibelle": 'Machines et équipements / Automobile' },
    { "MonLibelle": 'Métallurgie / Travail du métal' },
    { "MonLibelle": 'Plastique / Caoutchouc' },
    { "MonLibelle": 'Services aux entreprises' },
    { "MonLibelle": 'Textile / Habillement / Chaussure' },
    { "MonLibelle": 'Transports / Logistique' },
    { "MonLibelle": 'Autre' }
]
