import React, { useEffect, useState } from 'react'
import { connect, useSelector } from 'react-redux';
import { Field, reduxForm, change, getFormValues, formValueSelector,registerField } from 'redux-form';
import { makeStyles } from "@material-ui/core/styles";
import {
    getVille,
    getCivilite,
    getProfession,
    getPays,
    getSociete
} from 'redux/actions/apiActions'
import dayjs from 'dayjs'
import Typography from '@material-ui/core/Typography';
import CustomRadio from 'components/CustomRadio/CustomRadio'
import Paper from '@material-ui/core/Paper';
import CustomDate from 'components/CustomDate/CustomDate'
import CustomInput from "components/CustomInput/CustomInput";
import CustomSelect from 'components/CustomSelect/CustomSelect'
import CustomPhoneInput from 'components/CustomPhoneInput/CustomPhoneInput'
import { Divider } from "@material-ui/core";
import GridItem from "components/Grid/GridItem";
import GridContainer from "components/Grid/GridContainer";
import Button from "components/CustomButtons/Button.js";
import LabelledOutline from 'components/LabelledOutline/LabelledOutline'
import { green } from '@material-ui/core/colors';
import clsx from 'clsx';
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';
import AdherentConnexe from './AdherentConnexe';
const styles = {
    cardCategoryWhite: {
        "&,& a,& a:hover,& a:focus": {
            color: "rgba(255,255,255,.62)",
            margin: "0",
            fontSize: "14px",
            marginTop: "0",
            marginBottom: "0"
        },
        "& a,& a:hover,& a:focus": {
            color: "#FFFFFF"
        }
    },
    cardTitleWhite: {
        color: "#FFFFFF",
        marginTop: "0px",
        minHeight: "auto",
        fontWeight: "300",
        fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
        marginBottom: "3px",
        textDecoration: "none",
        "& small": {
            color: "#777",
            fontSize: "65%",
            fontWeight: "400",
            lineHeight: "1"
        }
    },
    cardCategoryWhite: {
        color: "rgba(255,255,255,.62)",
        margin: "0",
        fontSize: "14px",
        marginTop: "0",
        marginBottom: "0"
    },
    cardTitleWhite: {
        color: "#FFFFFF",
        marginTop: "0px",
        minHeight: "auto",
        fontWeight: "300",
        fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
        marginBottom: "3px",
        textDecoration: "none"
    },
    rootExpan: {
        width: '100%',
    },
    heading: {
        fontSize: 26,
        fontWeight: 'bold',
    },
    paper: {
        padding: '20px',
    },
    list: {
        width: '100%',
        padding: 5
    },

    rootfab: {

    },
    extendedIcon: {

    },
    btnnext:{
        display:'flex',
        justifyContent:'center',
        alignItems:'center',
        backgroundColor:green[400],
        paddingRight:20,
        paddingLeft:20,
        paddingTop:10,
        paddingBottom:10,
        color:'#ffffff',
        "&:hover":{
          backgroundColor:"#f9b233",
        },
        borderRadius:10,
      },
      btnback:{
        display:'flex',
        justifyContent:'center',
        alignItems:'center',
        backgroundColor:"#f9b233",
        paddingRight:20,
        paddingLeft:20,
        paddingTop:10,
        paddingBottom:10,
        color:'#ffffff',
        "&:hover":{
          backgroundColor:"#3c3c3b",
        },
        borderRadius:10,
      },
      cardbox:{
        marginBottom:20, 
        width:'85%',
        marginLeft:"auto",
        marginRight:"auto"
      }
};

const useStyles = makeStyles(styles);
function InfosEmprunteur(props) {
    const classes = useStyles();
    const { handleSubmit, handleBack, handleNext, steps, activeStep, api: { villes, professions, societes, civilites },dataForm } = props
    const {ui:{layoutRoot}} = props
    const history = useHistory()
    const handleAdConnexe =(dtInfos)=>{
        if(dtInfos){
            const infosOk = dtInfos.filter(item=>Object.keys(item).length !== 0)
            props.handleAdhentConnexe(infosOk)
        }
       
    }


    const backSimulation =()=>{
        history.push(`/${layoutRoot}/epret`,{fontion:'SIMUL'})
    }
    useEffect(() => {
        if (villes.length == 0) {
            props.getVille();
        }
        if (civilites.length == 0) {
            props.getCivilite();
        }
        if (professions.length == 0) {
            props.getProfession();
        }
        if (societes.length == 0) {
            props.getSociete();
        }

        window.scrollTo(0, 0)

    }, []);
    let mesVilles = useSelector(state => state.api.villes)
    const [inActiveStep,setInActiveStep] = useState(0)
    const handleNextIn =()=>{
        if(dataForm && dataForm.typeadherent=='Groupe'){
            if(parseInt(dataForm.nbadherent)>1){
                if(!dataForm.hasOwnProperty('adherentconex')){
                    setInActiveStep(inActiveStep + 1)
                }else{
                    if(inActiveStep===0){
                        setInActiveStep(inActiveStep + 1)
                    }else{
                        if(dataForm.adherentconex.length!==parseInt(dataForm.nbadherent)){
                            setInActiveStep(inActiveStep + 1)
                        }else{
                            handleNext() 
                        }
                    }
                }
            }else{
                handleNext()    
            }
        }else{
            handleNext() 
        }
    }
    const handleBackIn =()=>{
        if(dataForm && dataForm.typeadherent=='Groupe'){
            if(inActiveStep >0){
                setInActiveStep(inActiveStep-1)
            }else{
                backSimulation()
            }
        }else{
            backSimulation()
        }
    }
useEffect(()=>{
    if (!dataForm.hasOwnProperty('adherentconex')) {
        props.dispatch(registerField("addPret", 'adherentconex', 'Field'));
    }
},[])
    return (
        <form onSubmit={handleSubmit(handleNextIn)}>
            <Paper elevation={0} className={classes.paper} style={{marginBottom:20}}>
                <Typography className={classes.heading}>Etape {activeStep ? (activeStep + 1) : 1} : {(steps) ? steps[activeStep] : ""}</Typography>
                <Divider />
            </Paper>

            {inActiveStep===0&&<div>
                <Paper elevation={3} className={clsx([classes.paper,classes.cardbox])}>
                    <GridContainer>
                        <GridItem xs={12} sm={12} md={12} lg={12}>
                            <Field
                                required
                                component={CustomRadio}
                                label="Civilité *"
                                id="civiliteSous"
                                name='civiliteSous'
                                options={[{ "label": "Mme", "value": "Mme" }, { "label": "Mlle", "value": "Mlle" }, { "label": "M", "value": "M" }]}
                                formControlProps={{
                                    fullWidth: true
                                }}
                                row
                            />
                        </GridItem>
                    </GridContainer>
                    <GridContainer>
                        <GridItem xs={12} sm={12} md={4} lg={5}>
                            <Field
                                required
                                label="Nom"
                                id="nomSous"
                                name="nomSous"
                                variant="outlined"
                                formControlProps={{
                                    fullWidth: true
                                }}
                                component={CustomInput}
                                autoFocus
                            />
                        </GridItem>
                        <GridItem xs={12} sm={12} md={8} lg={7}>
                            <Field
                                required
                                label="Prénom"
                                id="prenomSous"
                                name="prenomSous"
                                formControlProps={{
                                    fullWidth: true
                                }}
                                variant="outlined"
                                component={CustomInput}
                                autoFocus
                            />
                        </GridItem>
                    </GridContainer>
                    <GridContainer>
                        <GridItem xs={12} sm={12} md={4} lg={5}>
                            <Field
                                required
                                label="Date de naissance"
                                id="datenaissance"
                                name="datenaissance"
                                formControlProps={{
                                    fullWidth: true,
                                }}
                                InputProps={{
                                    type: 'date',
                                    inputProps: { max: dayjs().add(-12, 'year').format('YYYY-MM-DD'), min: dayjs().add(-65, 'year').format('YYYY-MM-DD') },
                                }}
                                component={CustomDate}
                                variant="outlined"
                                autoFocus
                            />
                        </GridItem>
                        <GridItem xs={12} sm={12} md={8} lg={7}>
                            <Field
                                component={CustomSelect}
                                label="Lieu de naissance"
                                id="lieuNaissanceSous"
                                name="lieuNaissanceSous"
                                formControlProps={{
                                    fullWidth: true
                                }}
                                options={mesVilles}
                                variant="outlined"
                            />
                        </GridItem>
                    </GridContainer>
                    <GridContainer>
                    <GridItem xs={12} sm={12} md={8} lg={8}>
                            <Field
                                required
                                component={CustomRadio}
                                label="Nature de la pièce *"
                                id="naturePiece"
                                name='naturePiece'
                                options={[{ "label": "CNI", "value": "CNI" }, { "label": "Attestation", "value": "AT" }, { "label": "Passeport", "value": "Passport" }, { "label": "Autre pièce", "value": "Autre" }]}
                                formControlProps={{
                                    fullWidth: true
                                }}
                                row
                            />
                        </GridItem>
                        <GridItem xs={12} sm={12} md={4} lg={4}>
                            <Field
                                required
                                component={CustomInput}
                                label="N° pièce d'identité"
                                name='pieceIdentiteSous'
                                id="pieceIdentiteSous"
                                formControlProps={{
                                    fullWidth: true
                                }}
                                variant="outlined"
                            />
                        </GridItem>
                        
                    </GridContainer>
                </Paper>
                <Paper elevation={3} className={clsx([classes.paper,classes.cardbox])}>
                    <GridContainer>
                        <GridItem xs={12} sm={12} md={12} lg={12}>
                            <Field
                                required
                                label="Lieu de résidence"
                                id="lieuResidenceSous"
                                name='lieuResidenceSous'
                                formControlProps={{
                                    fullWidth: true
                                }}
                                options={mesVilles}
                                component={CustomSelect}
                            />
                        </GridItem>
                        <GridItem xs={12} sm={12} md={6} lg={6}>
                            <Field
                                component={CustomInput}
                                label="Adresse postale"
                                id="adressePostaleSous"
                                name="adressePostaleSous"
                                formControlProps={{
                                    fullWidth: true
                                }}
                                variant="outlined"
                            />
                        </GridItem>
                        <GridItem xs={12} sm={12} md={6} lg={6}>
                            <Field
                                component={CustomInput}
                                label="Adresse email"
                                id="emailSous"
                                name="emailSous"
                                formControlProps={{
                                    fullWidth: true
                                }}
                                type='email'
                                variant="outlined"
                            />
                        </GridItem>
                        <GridItem xs={12} sm={12} md={4} lg={4}>
                            {/* <Field
                                required
                                component={CustomPhoneInput}
                                label="Mobile 1 (whatsapp)"
                                id="mobileSous"
                                name="mobileSous"
                                formControlProps={{
                                    fullWidth: true
                                }}
                                InputProps={{
                                    inputProps: { max:10},
                                }}
                                variant="outlined"
                            /> */}

                            <Field
                                required
                                component={CustomPhoneInput}
                                label="Mobile 1 (WhatsApp)"
                                id="mobileSous"
                                name="mobileSous"
                                formControlProps={{
                                    fullWidth: true
                                }}
                                InputProps={{
                                    inputProps: { 
                                        maxLength: 15,  // Limite le nombre de caractères à 15
                                    },
                                }}
                                variant="outlined"
                                error={dataForm.mobileSous && dataForm.mobileSous.length > 15} // Vérification de l'erreur
                                helperText={dataForm.mobileSous && dataForm.mobileSous.length > 15 ? "Le numéro doit contenir 15 caractères maximum." : ""} // Message d'erreur
                            />

                        </GridItem>
                        <GridItem xs={12} sm={12} md={4} lg={4}>
                            <Field
                                component={CustomPhoneInput}
                                label="Mobile 2"
                                id="mobile2Sous"
                                name="mobile2Sous"
                                formControlProps={{
                                    fullWidth: true
                                }}
                                InputProps={{
                                    inputProps: { 
                                        maxLength: 15,  // Limite le nombre de caractères à 15
                                    },
                                }}
                                variant="outlined"
                            />
                        </GridItem>
                        <GridItem xs={12} sm={12} md={4} lg={4}>
                            <Field
                                component={CustomPhoneInput}
                                label="Téléphone bureau"
                                id="telephoneSous"
                                name='telephoneSous'
                                formControlProps={{
                                    fullWidth: true
                                }}
                                InputProps={{
                                    inputProps: { 
                                        maxLength: 15,  // Limite le nombre de caractères à 15
                                    },
                                }}
                                variant="outlined"
                            />
                        </GridItem>
                    </GridContainer>
                </Paper>
                <Paper elevation={3} className={clsx([classes.paper,classes.cardbox])}>
                    <GridContainer>
                        <GridItem xs={12} sm={12} md={6} lg={6}>
                            <Field
                                required
                                label="Activité professionnelle"
                                id="professionSous"
                                name='professionSous'
                                formControlProps={{
                                    fullWidth: true
                                }}
                                options={professions}
                                component={CustomSelect}
                            />
                        </GridItem>
                        <GridItem xs={12} sm={12} md={5} lg={6}>
                            <Field
                                required
                                label="Secteur d'activité"
                                id="employeur"
                                name='employeur'
                                formControlProps={{
                                    fullWidth: true
                                }}
                                options={secteurActivites}
                                component={CustomSelect}
                            />
                        </GridItem>
                    </GridContainer>
                </Paper>
                <Paper elevation={3} className={clsx([classes.paper,classes.cardbox])}>
                    <GridContainer>
                        <GridItem xs={12} sm={12} md={12}>
                            <LabelledOutline label="Personnes à contacter en cas de besoins">
                                <GridContainer>
                                    <GridItem xs={12} sm={12} md={6} lg={6}>
                                        <Field
                                            required
                                            label="Nom & prénom"
                                            id="personneressource"
                                            name="personneressource"
                                            variant="outlined"
                                            formControlProps={{
                                                fullWidth: true
                                            }}
                                            component={CustomInput}
                                        />
                                    </GridItem>
                                    <GridItem xs={12} sm={12} md={6} lg={6}>
                                        <Field
                                            required
                                            label="Contact"
                                            id="contactpersonneressource"
                                            name="contactpersonneressource"
                                            variant="outlined"
                                            formControlProps={{
                                                fullWidth: true
                                            }}
                                            InputProps={{
                                                inputProps: { 
                                                    maxLength: 15,  // Limite le nombre de caractères à 15
                                                },
                                            }}
                                            component={CustomPhoneInput}
                                        />
                                    </GridItem>
                                </GridContainer>
                                <GridContainer>
                                    <GridItem xs={12} sm={12} md={6} lg={6}>
                                        <Field
                                            required
                                            label="Nom & prénom"
                                            id="personneressource2"
                                            name="personneressource2"
                                            variant="outlined"
                                            formControlProps={{
                                                fullWidth: true
                                            }}
                                            component={CustomInput}
                                        />
                                    </GridItem>
                                    <GridItem xs={12} sm={12} md={6} lg={6}>
                                        <Field
                                            required
                                            label="Contact"
                                            id="contactpersonneressource2"
                                            name="contactpersonneressource2"
                                            variant="outlined"
                                            formControlProps={{
                                                fullWidth: true
                                            }}
                                            InputProps={{
                                                inputProps: { 
                                                    maxLength: 15,  // Limite le nombre de caractères à 10
                                                },
                                            }}
                                            component={CustomPhoneInput}
                                        />
                                    </GridItem>
                                </GridContainer>
                            </LabelledOutline>
                        </GridItem>
                    </GridContainer>
                </Paper>
            </div>}
            {(inActiveStep>0 && dataForm && dataForm.typeadherent==='Groupe')&&<AdherentConnexe handleAdConnexe={handleAdConnexe} />}
           

            <Divider />
            <div  className={clsx([classes.paper,classes.cardbox])} style={{ display: "flex", justifyContent: "space-between", padding: 20 }}>
                <Button onClick={handleBackIn} className={classes.btnback} variant="contained">Retour</Button>
                <Button 
                    variant="contained" 
                    color="primary" 
                    type='submit' 
                    className={classes.btnnext} 
                    large>{activeStep === steps.length - 1 ? 'Valider' : 'Suivant'}</Button>
            </div>    
        </form>
    )
}
const mapStateToProps = (state) => {
    return {
        api: state.api,
        ui: state.UI,
        dataForm: getFormValues('addPret')(state),
        initialValues: state.form.addPret.values
    }
};
const selector = formValueSelector('addPret')
const updateField = (field, data) => (dispatch) => dispatch(change('addPret', field, data))
const mapActionsToProps = (state) => {
    return {
        updateField,
        getVille,
        getPays,
        getProfession,
        getCivilite,
        getSociete,
    }
}
export default connect(mapStateToProps, mapActionsToProps)(reduxForm({form:'addPret', destroyOnUnmount:false})(InfosEmprunteur))

const secteurActivites = [
    { 'MonLibelle': 'AGENT ADMINISTRATIF' },
    { 'MonLibelle': "AGENT D'ASSURANCE / BANQUE / ETS FINANCIER" },
    { 'MonLibelle': 'ARCHITECTE' },
    { 'MonLibelle': 'ARTISAN (BOULANGER ,MENUSIER , COIFFEUR , CORDONNIER)' },
    { 'MonLibelle': 'ARTISTE (CHANTEUR , MUSICIEN , PEINTRE , DECORATEUR , CINEASTRE ,ACTEUR) ' },
    { 'MonLibelle': 'ASSISTANT( E ) DE DIRECTION (SECRETAIRE , ATTACHE DE DIRECTION, …)' },
    { 'MonLibelle': 'ASSISTANTE SOCIALE' },
    { 'MonLibelle': 'AVOCAT' },
    { 'MonLibelle': 'CADRE ADMINISTRATI F ( PRIVE )' },
    { 'MonLibelle': 'CADRE COMMERCIAL' },
    { 'MonLibelle': 'CADRE FINANCIER (PRIVE)' },
    { 'MonLibelle': 'CHIRURGIEN DENTISTE' },
    { 'MonLibelle': "CLERC DE NOTAIRE , D'HUISIER ,  D'AVOCAT" },
    { 'MonLibelle': 'COMMERCANT' },
    { 'MonLibelle': 'COMPTABLE PRIVE' },
    { 'MonLibelle': 'CONSEILLER  JURIDIQUE' },
    { 'MonLibelle': 'DELEGUE MEDICAL' },
    { 'MonLibelle': 'DIRECTEUR ADMINISTRATIF ET FINANCIER' },
    { 'MonLibelle': 'DIRECTEUR COMMERCIAL' },
    { 'MonLibelle': "DIRECTEUR D'ENTREPRISE" },
    { 'MonLibelle': 'DIRECTEUR TECHNIQUE' },
    { 'MonLibelle': 'ENSEIGNANT DU PRIMAIRE' },
    { 'MonLibelle': 'ENSEIGNANT DU SECONDAIRE' },
    { 'MonLibelle': 'ENSEIGNANT DU SUPERIEUR' },
    { 'MonLibelle': 'ENTREPRENEUR' },
    { 'MonLibelle': 'EXPERT COMPTABLE' },
    { 'MonLibelle': 'EXPLOITANT AGRICOLE OU FORESTIER' },
    { 'MonLibelle': 'FONCTIONNAIRE' },
    { 'MonLibelle': 'HAUT FONCTIONNAIRE' },
    { 'MonLibelle': 'HUISSIER DE JUSTICE' },
    { 'MonLibelle': 'INFIRMIER' },
    { 'MonLibelle': 'INFORMATICIEN' },
    { 'MonLibelle': 'INGENIEUR' },
    { 'MonLibelle': 'JOURNALISTE' },
    { 'MonLibelle': 'M EDECIN' },
    { 'MonLibelle': 'NOTAIRE' },
    { 'MonLibelle': 'OFFICIER (POLICE , MILITAIRE , GENDARMERIE)' },
    { 'MonLibelle': 'OPTICIEN' },
    { 'MonLibelle': 'OUVRIER' },
    { 'MonLibelle': 'PHARMACIEN' },
    { 'MonLibelle': 'PUBLICITAIR E' },
    { 'MonLibelle': 'RELIGIEUX' },
    { 'MonLibelle': 'RETRAITE' },
    { 'MonLibelle': 'SAGE FEMME' },
    { 'MonLibelle': 'SALARIE ORGANISME  INTERNATIONAL' },
    { 'MonLibelle': 'SANS  PROFESSION' },
    { 'MonLibelle': 'SOUS-OFFICIER (POLICE , MILITAIRE , GENDARMERIE)' },
    { 'MonLibelle': 'TECHNICIEN (AUTO , PHOTO , TEXTIL ,MODE)' },
    { 'MonLibelle': 'TRANSPORTEUR' },
    { 'MonLibelle': 'SANTE ET BIEN ETRE ' },
    { 'MonLibelle': 'EDUCATION' },
    { 'MonLibelle': 'CONSTRUCTION' },
    { 'MonLibelle': 'SOUS-TRAITANT GRANDES ENTREPRISES' },
    { 'MonLibelle': 'AUTRES PME' }]