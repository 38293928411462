import React from 'react';
import withStyles from '@material-ui/core/styles/withStyles';
import 'react-animated-slider/build/horizontal.css';
import 'normalize.css/normalize.css';
import './styles/slider-animations.css';
import './styles/styles.css';

import Homer from 'components/Home'
import LoginHomer from 'pages/Login/LoginHomer'
import { useHistory } from 'react-router-dom';


//style
const styles = (theme) => ({
  root: {
    minHeight:'100%'
  },
})


function Home(props) {
  const { classes } = props
  const history = useHistory()
  return (
    <div className={classes.root}>
      <Homer.Inner>
        <Homer.Body>
          <Homer.Group salign='center' palign='center'>
            <LoginHomer history={history} />
          </Homer.Group>
        </Homer.Body>
      </Homer.Inner>
    </div>
  );
}

export default withStyles(styles)(Home)