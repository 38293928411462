import React ,{useState,useEffect} from 'react'
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import {Grid} from '@material-ui/core';
import Button from "components/CustomButtons/Button.js";
import { withStyles } from '@material-ui/core/styles';
import ProfilModalFormBenef from './ProfilModalFormBenef'
import Typography from "@material-ui/core/Typography";
import Divider from '@material-ui/core/Divider';
import NavigateNextOutlinedIcon from '@material-ui/icons/NavigateNextOutlined';
import { connect, } from 'react-redux';
import { Field, reduxForm,getFormValues,change,registerField } from 'redux-form'

import {formValueSelector} from 'redux-form'

import DeleteIcon from '@material-ui/icons/Delete';
import IconButton from '@material-ui/core/IconButton';

import GridItem from "components/Grid/GridItem.js";
import GridContainer from "components/Grid/GridContainer.js";
import CustomCheckBox from 'components/CustomCheckBox/CustomCheckBox'
import dayjs from 'dayjs'
const styles = theme =>({
  root: {
    backgroundColor:theme.palette.primary.main,
    borderRadius:50,
    border: 1,
    color: 'white',
    height: 48,
    padding: '0 30px',
    boxShadow: '0 3px 5px 2px rgba(255, 105, 135, .3)',
    marginBottom:20,
    '&:hover':{
      backgroundColor:theme.palette.secondary.main,
      boxShadow: '0 3px 5px 2px rgba(6, 6, 6, .3)',
    }
  },
    table: {
        minWidth: 650,
      },
      btnAdd:{
          
      },
      btnAction:{
        width:80,
        height:80,
        backgroundColor:'#fff',
        color:theme.palette.primary.main,
        '&:hover':{
          backgroundColor:theme.palette.primary.main,
          color:theme.palette.primary.contrastText
        }
      },
      btnback:{
        display:'flex',
        justifyContent:'center',
        alignItems:'center',
        backgroundColor:"#f9b233",
        paddingRight:20,
        paddingLeft:20,
        paddingTop:10,
        paddingBottom:10,
        color:'#ffffff',
        "&:hover":{
          backgroundColor:"#3c3c3b",
        },
        borderRadius:10,
      },
      btnnext:{
        display:'flex',
        justifyContent:'center',
        alignItems:'center',
        backgroundColor:theme.palette.primary.main,
        paddingRight:20,
        paddingLeft:20,
        paddingTop:10,
        paddingBottom:10,
        color:'#ffffff',
        "&:hover":{
          backgroundColor:"#f9b233",
        },
        borderRadius:10,
      },
      hTrait:{
        marginTop:20,
        marginBottom:20
    },
})


  

 function ProfilBeneficiaire(props) {
    const { handleSubmit,classes,data } = props

    const [mesAssures,setMesAssures] = useState([])
    const [open, setOpen] = React.useState(false);
    const [moimemeBeneficiaire, setMoimemeBeneficiaire] = React.useState(false);

    const [modeBeneficaire,setModeBeneficaire] = React.useState('Tous');
    const [modeOpen,setModeOpen] = React.useState('autreBeneficaire')
        const handleOpen = () => {
          setOpen(true);
        };
    
        const handleClose = () => {
          setOpen(false);
        };
        const handleMoimemeBeneficaire =(mytype=null)=>{
          const moimeme =!moimemeBeneficiaire;
          setMoimemeBeneficiaire(moimeme)
          if(moimeme){
           
           const currentBeneficiaire ={
              nomBeneficiaire: data.nomSous && data.nomSous, 
              prenomBeneficiaire: data.prenomSous && data.prenomSous,
              dateNaissanceBeneficiaire: data.dateNaissanceSous && data.dateNaissanceSous,
              lieuNaissanceBeneficiaire: data.lieuNaissanceSous && data.lieuNaissanceSous,
              lieuResidenceBeneficiaire: data.lieuResidenceSous && data.lieuResidenceSous,
              filiationBeneficiaire: 'Moi même',
              emailBeneficiaire: data.emailSous && data.emailSous,
              telephoneBeneficiaire: data.mobileSous && data.mobileSous,
              taux:'100',
              monIndex: (mesAssures.length+1),
              type:mytype
            } 
            let newbeneficiaires =  mesAssures
            newbeneficiaires =[...newbeneficiaires,currentBeneficiaire]
            props.updateField('beneficiaires',newbeneficiaires)
            setMesAssures(newbeneficiaires)
          }else{
            setModeOpen('autreBeneficiaire')
          }
        }
        const handleAddAssure =(mesdonne)=>{
          if(mesdonne){
            mesdonne.type =modeBeneficaire
            let newbeneficiaires = mesAssures
            newbeneficiaires= [...newbeneficiaires,mesdonne]
            props.updateField('beneficiaires',newbeneficiaires)
            setMesAssures(newbeneficiaires)
            handleClose()
          }  
        }
        const deletebeneficaire =(assure)=>{
            let crurrentAsuree = mesAssures   
            crurrentAsuree =  crurrentAsuree.filter((item=>item.monIndex!==assure.monIndex))
            props.updateField('beneficiaires',crurrentAsuree)
            setMesAssures(crurrentAsuree)
        }
     
        useEffect(() => {
          if(!data.hasOwnProperty('beneficiaires')){
            props.dispatch(registerField("adForm",'beneficiaires','Field'));
            props.updateField('beneficiaires',mesAssures)
          }else{
            if(mesAssures.length===0){
              setMesAssures(data.beneficiaires)
            }
          }
        }, [mesAssures])
   
    const renderBeneficiaire =(props)=>{
     
     return( props.input.value.length!==0? (
        <TableContainer component={Paper}>
          <Table className={classes.table} aria-label="simple table">
            <TableHead>
              <TableRow>
                <TableCell align="center" >Nom</TableCell>
                <TableCell align="center" >Prénoms</TableCell>
                <TableCell align="center">Né(e) le</TableCell>
                <TableCell align="center">Lieu de naissance</TableCell>
                <TableCell align="center">Lieu de residence</TableCell>
                <TableCell align="center">Filiation</TableCell>
                <TableCell align="center">Téléphone</TableCell>
                <TableCell align="center">Email</TableCell>
                <TableCell align="center">Taux (%)</TableCell>
                <TableCell align="center">Actions</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
            {props.input.value.map(assure => (
                <TableRow key={assure.nomBeneficiaire}>
                  <TableCell align="center" component="th" scope="row">
                    {assure.nomBeneficiaire}
                  </TableCell>
                  <TableCell align="center">{assure.prenomBeneficiaire}</TableCell>
                  <TableCell align="center">{assure.dateNaissanceBeneficiaire?dayjs(assure.dateNaissanceBeneficiaire).format('DD/MM/YYYY'):null}</TableCell>
                  <TableCell align="center">{assure.lieuNaissanceBeneficiaire}</TableCell>
                  <TableCell align="center">{assure.lieuResidenceBeneficiaire}</TableCell>
                  <TableCell align="center">{assure.filiationBeneficiaire}</TableCell>
                  <TableCell align="center">{assure.telephoneBeneficiaire}</TableCell>
                  <TableCell align="center">{assure.emailBeneficiaire}</TableCell>
                  <TableCell align="center">{assure.taux}</TableCell>
                  <TableCell align="center">
                  <IconButton
                      data-id={assure.numeroCniAssure}
                      aria-label="actions"
                      aria-haspopup="true"
                      onClick={()=>deletebeneficaire(assure)}
                    >
                    <DeleteIcon />
                    </IconButton>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
      </TableContainer>):((!data.beneficiaireaudeces && !data.beneficiaireauterme)?<Typography>Aucun bénéficiaire </Typography>:null)
     )}
    const handleClickTypeBenef =(event,type)=>{
       const {value} = event.target
       if(data.hasOwnProperty('beneficiaireauterme') && Array.isArray(data.beneficiaireauterme)){
         if(value==='autre' && !data.beneficiaireauterme.includes('autre') && type==='AUTERME'){
          handleOpen()
          setModeBeneficaire('AUTERME')
         }
       }
       if(data.hasOwnProperty('beneficiaireaudeces') && Array.isArray(data.beneficiaireaudeces)){
        if(value==='autre' && !data.beneficiaireaudeces.includes('autre') && type==='AUDECES'){
         handleOpen()
         setModeBeneficaire('AUDECES')
        }
        if(value==='adherent' && !data.beneficiaireaudeces){
          handleMoimemeBeneficaire('AUDECES')
        }else{
          if(value==='adherent' &&  !data.beneficiaireaudeces.includes('adherent')){
            handleMoimemeBeneficaire('AUDECES')
          }
        }
      }
      if(value==='adherent' && !data.beneficiaireauterme){
        handleMoimemeBeneficaire('AUTERME')
      }else{
        if(value==='adherent' &&  !data.beneficiaireauterme.includes('adherent')){
          handleMoimemeBeneficaire('AUTERME')
        }
      }
      
    }

    // return (

    // <Grid item sm={12} xs={12} >
    //     <ProfilModalFormBenef handleClose={handleClose} handleOpen={handleOpen} open={open} handleAddAssure={handleAddAssure} />
    //       {
    //         <GridContainer>
    //           <GridItem xs={12} sm={6} md={6} lg={6}>
    //             <Field
    //                 label="Au terme du contrat"
    //                 id="beneficiaireauterme"
    //                 name="beneficiaireauterme"
    //                 options={[
    //                   {"label":"L'adherent","value":"adherent"},
    //                   {"label":"Le conjoint non divorcé, ni séparé de corps","value":"conjoint"},
    //                   {"label":"Les enfants nés et à naître","value":"enfants"},
    //                   {"label":"Autres, Préciser","value":"autre"}
    //                 ]}
    //                 formControlProps={{
    //                     fullWidth: true
    //                 }}
    //                 component={CustomCheckBox}
    //                 onClick={(e)=>handleClickTypeBenef(e,'AUTERME')}
    //             />
    //           </GridItem>
    //           {/*choix de bénéficiaire en cas de décès avant le terme */}
    //           <GridItem xs={12} sm={6} md={6} lg={6}>
    //             <Field
    //                 label="En cas de décès avant le terme"
    //                 id="beneficiaireaudeces"
    //                 name="beneficiaireaudeces"
    //                 options={(data.produit.CodeProduit==='YKV_2004')?[
    //                   {"label":"L'adherent","value":"adherent"},
    //                   {"label":"Le conjoint non divorcé, ni séparé de corps","value":"conjoint"},
    //                   {"label":"Les enfants nés et à naître","value":"enfants"},
    //                   {"label":"Autres, Préciser (ajouter des bénéficiaires) ","value":"autre"}
    //                 ]:[
    //                   {"label":"Le conjoint non divorcé, ni séparé de corps","value":"conjoint"},
    //                   {"label":"Les enfants nés et à naître","value":"enfants"},
    //                   {"label":"Autres, Préciser (ajouter des bénéficiaires) ","value":"autre"}
    //                 ]}
    //                 formControlProps={{
    //                     fullWidth: true
    //                 }}
    //                 component={CustomCheckBox}
    //                 onClick={(e)=>handleClickTypeBenef(e,'AUDECES')}
    //             />
    //           </GridItem>
    //         </GridContainer>
    //       }  
    //     <Field 
    //         name="beneficiaires"
    //         component={renderBeneficiaire}
    //     /> 
       
    //         <Divider component="hr" className={classes.hTrait}/>
    //         <Grid container direction="row" justify="space-between" alignItems="center">        
             
    //          <Button type="button" className={classes.btnback} onClick={props.handleBack}>
    //            Retour
    //          </Button>
          
           
    //          <Button type= 'submit' className={classes.btnnext} onClick={handleSubmit} disabled={(mesAssures.length===0)?(((data.hasOwnProperty('beneficiaireauterme') && data.beneficiaireauterme.length!==0) || (data.hasOwnProperty('beneficiaireauterme') && data.beneficiaireauterme.length!==0))?false:true):false}>
    //            Continuer
    //            <NavigateNextOutlinedIcon /> 
    //          </Button>
         
    //      </Grid>

      
    // </Grid>
      
    // );


    return (
      <Grid item sm={12} xs={12}>
        <ProfilModalFormBenef
          handleClose={handleClose}
          handleOpen={handleOpen}
          open={open}
          handleAddAssure={handleAddAssure}
        />
        {
          <GridContainer>
            {data.produit.CodeProduit !== 'ASSCPTBNI' && (
              <GridItem xs={12} sm={6} md={6} lg={6}>
                <Field
                  label="Au terme du contrat"
                  id="beneficiaireauterme"
                  name="beneficiaireauterme"
                  options={
                    [
                    { label: "L'adherent", value: "adherent" },
                    { label: "Le conjoint non divorcé, ni séparé de corps", value: "conjoint" },
                    { label: "Les enfants nés et à naître", value: "enfants" },
                    { label: "Autres, Préciser", value: "autre" },
                  ]
                  }
                  formControlProps={{
                    fullWidth: true,
                  }}
                  component={CustomCheckBox}
                  onClick={(e) => handleClickTypeBenef(e, 'AUTERME')}
                />
              </GridItem>
            )}
            {/* choix de bénéficiaire en cas de décès avant le terme */}
            <GridItem xs={12} sm={6} md={6} lg={6}>
              <Field
                label="En cas de décès avant le terme"
                id="beneficiaireaudeces"
                name="beneficiaireaudeces"
                options={
                  data.produit.CodeProduit === 'YKV_2004'
                    ? [
                        { label: "L'adherent", value: "adherent" },
                        { label: "Le conjoint non divorcé, ni séparé de corps", value: "conjoint" },
                        { label: "Les enfants nés et à naître", value: "enfants" },
                        { label: "Autres, Préciser (ajouter des bénéficiaires)", value: "autre" },
                      ]
                    : [
                        { label: "Le conjoint non divorcé, ni séparé de corps", value: "conjoint" },
                        { label: "Les enfants nés et à naître", value: "enfants" },
                        { label: "Autres, Préciser (ajouter des bénéficiaires)", value: "autre" },
                      ]
                }
                formControlProps={{
                  fullWidth: true,
                }}
                component={CustomCheckBox}
                onClick={(e) => handleClickTypeBenef(e, 'AUDECES')}
              />
            </GridItem>
          </GridContainer>
        }
        <Field name="beneficiaires" component={renderBeneficiaire} />
    
        <Divider component="hr" className={classes.hTrait} />
        <Grid container direction="row" justify="space-between" alignItems="center">
          <Button type="button" className={classes.btnback} onClick={props.handleBack}>
            Retour
          </Button>
    
          <Button
            type="submit"
            className={classes.btnnext}
            onClick={handleSubmit}
            disabled={
                data.produit.CodeProduit === 'ASSCPTBNI'
              ?
                (data.hasOwnProperty('beneficiaireauterme') && data.beneficiaireauterme.length !== 0)
              : mesAssures.length === 0
                ? (data.hasOwnProperty('beneficiaireauterme') && data.beneficiaireauterme.length !== 0)
                 || (data.hasOwnProperty('beneficiaireauterme') && data.beneficiaireauterme.length !== 0)
                  ? false
                  : true
                : false
            }
          >
            Continuer
            <NavigateNextOutlinedIcon />
          </Button>
        </Grid>
      </Grid>
    );
    
  }

  const selector = formValueSelector('adForm')
  const updateField =(field, data)=>(dispatch)=>dispatch(change('adForm', field, data))
  const mapActionsToProps = {
    updateField
  }
  const mapStateProps =(state)=>({
    data:getFormValues('adForm')(state),
  })


  ProfilBeneficiaire =withStyles(styles)(ProfilBeneficiaire)
  
  export default connect(mapStateProps,mapActionsToProps)(reduxForm({form: 'adForm',destroyOnUnmount: false})(ProfilBeneficiaire))
