import React, { useState, useEffect, useReducer, useCallback, useRef } from "react";
// @material-ui/core components
import { makeStyles, withStyles } from "@material-ui/core/styles";

import { Field, getFormValues, reduxForm, formValueSelector, change, registerField, reset, initialize } from 'redux-form';
import { connect, useDispatch } from 'react-redux';
import {
    getVille,
    getCivilite,
    getProfession,
    getPays,
    getSociete,
    getTauxIndiceMasse,
    getTauxInteret,
    getTauxSurmortalite,
    getExamenExiger,
    savegardePret,
    getTauxDiabete,
    getTauxTension
} from 'redux/actions/apiActions'

import dayjs from 'dayjs'
import { ageAssure, ageBorne } from 'redux/actions/userActions'
import MuiAlert from '@material-ui/lab/Alert';
import Snackbar from '@material-ui/core/Snackbar';
import Stepper from '@material-ui/core/Stepper';
import Step from '@material-ui/core/Step';
import StepLabel from '@material-ui/core/StepLabel';
import InfosEmprunteur from "./InfosEmprunteur"
import InfosPret from './InfosPret'
import InfosSante from './InfosSante'
import InfosBeneficiaire from './InfosBeneficiaire'
import PretFicheresume from 'pages/Banking/Prets/components/PretFicheresume'
import CustomInput from "components/CustomInput/CustomInput"
import CustomRadio from 'components/CustomRadio/CustomRadio'
import { evaluationPret, formatNumber } from 'application'
import Paper from '@material-ui/core/Paper';
import { Pret } from './components/pret'
import { Divider,IconButton,Tooltip } from "@material-ui/core";
import Typography from "@material-ui/core/Typography";
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import red from '@material-ui/core/colors/red'
import green from '@material-ui/core/colors/green'
import AlertSucces from '@material-ui/lab/Alert';
import CheckIcon from '@material-ui/icons/Check';
import Slide from '@material-ui/core/Slide';
import Button from "components/CustomButtons/Button.js";
import { useHistory, useLocation } from "react-router-dom";
import DocumentPret from 'pages/Banking/components/DocumentPret'
import RotateLeftIcon from '@material-ui/icons/RotateLeft';


const styles = {
    rootm:{
        width:'85%',
        marginLeft:'auto',
        marginRight:'auto',
        marginTop:75
      },

    cardCategoryWhite: {
        "&,& a,& a:hover,& a:focus": {
            color: "rgba(255,255,255,.62)",
            margin: "0",
            fontSize: "14px",
            marginTop: "0",
            marginBottom: "0"
        },
        "& a,& a:hover,& a:focus": {
            color: "#FFFFFF"
        }
    },
    cardTitleWhite: {
        color: "#FFFFFF",
        marginTop: "0px",
        minHeight: "auto",
        fontWeight: "300",
        fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
        marginBottom: "3px",
        textDecoration: "none",
        "& small": {
            color: "#777",
            fontSize: "65%",
            fontWeight: "400",
            lineHeight: "1"
        }
    },
    cardCategoryWhite: {
        color: "rgba(255,255,255,.62)",
        margin: "0",
        fontSize: "14px",
        marginTop: "0",
        marginBottom: "0"
    },
    cardTitleWhite: {
        color: "#FFFFFF",
        marginTop: "0px",
        minHeight: "auto",
        fontWeight: "300",
        fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
        marginBottom: "3px",
        textDecoration: "none"
    },
    rootExpan: {
        width: '100%',
    },
    heading: {
        fontSize: 26,
        fontWeight: 'bold',
    },

    list: {
        width: '100%',
        padding: 5
    },

    paper: {
        whiteSpace: 'nowrap',
        background: 'none',
    },
    paperP: {
        display: "flex",
        flexDirection: "column",
        background: '#FFF1EB',
        color: "#fff",
        width: "100%",
        padding: '20px',
        marginTop: "20px",
        marginBottom: "20px"
    },
    hPaper:{
        padding:10,
        marginBottom:20,
        borderLeftColor: green[400],
        borderLeftWidth:8,
        display:'flex',
        justifyContent:'space-between',
        alignItems:'center',
        marginLeft:20
    },
    btnnext:{
        display:'flex',
        justifyContent:'center',
        alignItems:'center',
        backgroundColor:green[400],
        paddingRight:20,
        paddingLeft:20,
        paddingTop:10,
        paddingBottom:10,
        color:'#ffffff',
        "&:hover":{
          backgroundColor:"#f9b233",
        },
        borderRadius:10,
      },
      btnback:{
        display:'flex',
        justifyContent:'center',
        alignItems:'center',
        backgroundColor:"#f9b233",
        paddingRight:20,
        paddingLeft:20,
        paddingTop:10,
        paddingBottom:10,
        color:'#ffffff',
        "&:hover":{
          backgroundColor:"#3c3c3b",
        },
        borderRadius:10,
      },
};

const useStyles = makeStyles(styles);
function Alert(props) {
    return <MuiAlert elevation={6} variant="filled" {...props} />;
}


const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});
//button succès
const SuccesButton = withStyles((theme) => ({
    root: {
        color: theme.palette.getContrastText(green[500]),
        backgroundColor: green[500],
        '&:hover': {
            backgroundColor: green[700],
        },
    },
}))(Button);
//bouton error
const ErrorButton = withStyles((theme) => ({
    root: {
        color: theme.palette.getContrastText(red[500]),
        backgroundColor: red[500],
        '&:hover': {
            backgroundColor: red[700],
        },
    },
}))(Button);

function getSteps() {
    return ["Informations sur l'adhérent", 'Détails de la cotation', "Questionnaire sur l'état de santé", "Bénéficiaire garantie YAKO", "Résumé/Choix d'un médecin", "Documents"];
}


function AddPret(props) {

    const classes = useStyles();
    const { dataForm:{duree, datenaissance, prime, montantpret,primerisque, examensup},user, dataForm, ui: { layoutRoot } } = props
    const [loading, setLoading] = useState(false)
    const { credentials } = user
    const history = useHistory()
    const dispatch = useDispatch()
    const params = useLocation()
  
    let containerref = useRef()
 
    const displayDataMode = useCallback(() => {
        if (dataForm && dataForm.hasOwnProperty('mode') && dataForm.mode === 'UPDATE') {
          
            const { adherent, pret, beneficiaires, questionnaire } = params.state
           
            if (adherent) {
                if (adherent.hasOwnProperty('civilite')) {
                    dispatch(registerField("addPret", 'civiliteSous', 'Field'));
                    props.updateField('civiliteSous', adherent.civilite ? adherent.civilite : null)
                }
                if (adherent.hasOwnProperty('nom')) {
                    dispatch(registerField("addPret", 'nomSous', 'Field'));
                    props.updateField('nomSous', adherent.nom ? adherent.nom : null)
                }

                if (adherent.hasOwnProperty('prenom')) {
                    dispatch(registerField("addPret", 'prenomSous', 'Field'));
                    props.updateField('prenomSous', adherent.prenom ? adherent.prenom : null)
                }

                if (adherent.hasOwnProperty('datenaissance')) {
                    dispatch(registerField("addPret", 'datenaissance', 'Field'));
                    props.updateField('datenaissance', adherent.datenaissance ? dayjs(adherent.datenaissance).format('YYYY-DD-MM') : null)
                }

                if (adherent.hasOwnProperty('lieunaissance')) {
                    dispatch(registerField("addPret", 'lieuNaissanceSous', 'Field'));
                    props.updateField('lieuNaissanceSous', adherent.lieunaissance ? adherent.lieunaissance : null)
                }
                if (adherent.hasOwnProperty('lieuresidence')) {
                    dispatch(registerField("addPret", 'lieuResidenceSous', 'Field'));
                    props.updateField('lieuResidenceSous', adherent.lieuresidence ? adherent.lieuresidence : null)
                }

                if (adherent.hasOwnProperty('sexe')) {
                    dispatch(registerField("addPret", 'sexeSous', 'Field'));
                    props.updateField('sexeSous', adherent.sexe ? adherent.sexe : null)
                }

                if (adherent.hasOwnProperty('numeropiece')) {
                    dispatch(registerField("addPret", 'pieceIdentiteSous', 'Field'));
                    props.updateField('pieceIdentiteSous', adherent.numeropiece ? adherent.numeropiece : null)
                }

                if (adherent.hasOwnProperty('naturepiece')) {
                    dispatch(registerField("addPret", 'naturePiece', 'Field'));
                    props.updateField('naturePiece', adherent.naturepiece ? adherent.naturepiece : null)
                }

                if (adherent.hasOwnProperty('bp')) {
                    dispatch(registerField("addPret", 'adressePostaleSous', 'Field'));
                    props.updateField('adressePostaleSous', adherent.bp ? adherent.bp : null)
                }

                if (!adherent.hasOwnProperty('email')) {
                    dispatch(registerField("addPret", 'emailSous', 'Field'));
                    props.updateField('emailSous', adherent.email ? adherent.email : null)
                }

                if (adherent.hasOwnProperty('mobile')) {
                    dispatch(registerField("addPret", 'mobileSous', 'Field'));
                    props.updateField('mobileSous', adherent.mobile ? adherent.mobile : null)
                }

                if (adherent.hasOwnProperty('mobile1')) {
                    dispatch(registerField("addPret", 'mobile2Sous', 'Field'));
                    props.updateField('mobile2Sous', adherent.mobile1 ? adherent.mobile1 : null)
                }


                if (adherent.hasOwnProperty('telephone')) {
                    dispatch(registerField("addPret", 'telephoneSous', 'Field'));
                    props.updateField('telephoneSous', adherent.telephone ? adherent.telephone : null)
                }


                if (adherent.hasOwnProperty('profession')) {
                    dispatch(registerField("addPret", 'professionSous', 'Field'));
                    props.updateField('professionSous', adherent.profession ? adherent.profession : null)
                }


                if (adherent.hasOwnProperty('employeur')) {
                    dispatch(registerField("addPret", 'employeur', 'Field'));
                    props.updateField('employeur', adherent.employeur ? adherent.employeur : null)
                }
            }
            //informations prets
            if (pret) {
                if (pret.hasOwnProperty('id')) {
                    dispatch(registerField("addPret", 'id', 'Field'));
                    props.updateField('id', pret.id ? pret.id : null)
                }
                if(pret.hasOwnProperty('naturepret')){
                    dispatch(registerField("addPret", 'naturepret', 'Field'));
                    props.updateField('naturepret', pret.naturepret ? pret.naturepret : null)
                }
            
                if(pret.hasOwnProperty('codeadherent')){
                    dispatch(registerField("addPret", 'codeadherent', 'Field'));
                    props.updateField('codeadherent', pret.codeadherent ? pret.codeadherent : null)
                }
            
                if (pret.hasOwnProperty('montantpret')) {
                    dispatch(registerField("addPret", 'montantpret', 'Field'));
                    props.updateField('montantpret', pret.montantpret ? pret.montantpret : null)
                }

                if (pret.hasOwnProperty('duree')) {
                    dispatch(registerField("addPret", 'duree', 'Field'));
                    props.updateField('duree', pret.duree ? pret.duree : null)
                }

                if (pret.hasOwnProperty('capital')) {
                    dispatch(registerField("addPret", 'capitalSouscrit', 'Field'));
                    props.updateField('capitalSouscrit', pret.capital ? pret.capital : null)
                }

                if (pret.hasOwnProperty('primeobseque')) {
                    dispatch(registerField("addPret", 'primeobseque', 'Field'));
                    props.updateField('primeobseque', pret.primeobseque ? pret.primeobseque : null)
                }
                if (pret.hasOwnProperty('primerisque')) {
                    dispatch(registerField("addPret", 'primerisque', 'Field'));
                    props.updateField('primeobseque', pret.primerisque ? pret.primerisque : null)
                }
                
                if (pret.hasOwnProperty('examens')) {
                    dispatch(registerField("addPret", 'examens', 'Field'));
                    props.updateField('examens', pret.examens ? pret.examens : null)
                }
                if (pret.hasOwnProperty('unableyako')) {
                    dispatch(registerField("addPret", 'unableyako', 'Field'));
                    props.updateField('unableyako', pret.unableyako ? pret.unableyako : null)
                }
                if (pret.hasOwnProperty('personneressource')) {
                    dispatch(registerField("addPret", 'personneressource', 'Field'));
                    props.updateField('personneressource', pret.personneressource ? pret.personneressource : null)
                }
                if (pret.hasOwnProperty('contactpersonneressource')) {
                    dispatch(registerField("addPret", 'contactpersonneressource', 'Field'));
                    props.updateField('contactpersonneressource', pret.contactpersonneressource ? pret.contactpersonneressource : null)
                }
                if (pret.hasOwnProperty('personneressource2')) {
                    dispatch(registerField("addPret", 'personneressource2', 'Field'));
                    props.updateField('personneressource2', pret.personneressource2 ? pret.personneressource2 : null)
                }
                if (pret.hasOwnProperty('contactpersonneressource2')) {
                    dispatch(registerField("addPret", 'contactpersonneressource2', 'Field'));
                    props.updateField('contactpersonneressource2', pret.contactpersonneressource2 ? pret.contactpersonneressource2 : null)
                }
                if (pret.hasOwnProperty('dateemis')) {
                    dispatch(registerField("addPret", 'dateemis', 'Field'));
                    props.updateField('dateemis', pret.dateemis ? pret.dateemis : null)
                }
                if (pret.hasOwnProperty('daterapport')) {
                    dispatch(registerField("addPret", 'daterapport', 'Field'));
                    props.updateField('daterapport', pret.daterapport ? pret.daterapport : null)
                }
                if (pret.hasOwnProperty('datermedecin')) {
                    dispatch(registerField("addPret", 'datermedecin', 'Field'));
                    props.updateField('datermedecin', pret.datermedecin ? pret.datermedecin : null)
                }
                if (pret.hasOwnProperty('rmedicinok')) {
                    dispatch(registerField("addPret", 'rmedicinok', 'Field'));
                    props.updateField('rmedicinok', pret.rmedicinok ? pret.rmedicinok : null)
                }
                if (pret.hasOwnProperty('daterapportmedical')) {
                    dispatch(registerField("addPret", 'daterapportmedical', 'Field'));
                    props.updateField('daterapportmedical', pret.daterapportmedical ? pret.daterapportmedical : null)
                }
                if (pret.hasOwnProperty('rapportmedicalok')) {
                    dispatch(registerField("addPret", 'rapportmedicalok', 'Field'));
                    props.updateField('rapportmedicalok', pret.rapportmedicalok ? pret.rapportmedicalok : null)
                }
                if (pret.hasOwnProperty('medecin')) {
                    dispatch(registerField("addPret", 'medecin', 'Field'));
                    props.updateField('medecin', pret.medecin ? pret.medecin : null)
                }
                if (pret.hasOwnProperty('tarificationmedicale')) {
                    dispatch(registerField("addPret", 'tarificationmedicale', 'Field'));
                    props.updateField('tarificationmedicale', pret.tarificationmedicale ? pret.tarificationmedicale : null)
                }
                if (pret.hasOwnProperty('fraismedicaux')) {
                    dispatch(registerField("addPret", 'fraismedicaux', 'Field'));
                    props.updateField('fraismedicaux', pret.fraismedicaux ? pret.fraismedicaux : null)
                }

                if (pret.hasOwnProperty('nbadherent')) {
                    dispatch(registerField("addPret", 'nbadherent', 'Field'));
                    props.updateField('nbadherent', pret.nbadherent ? pret.nbadherent : null)
                }
                if (pret.hasOwnProperty('typeadherent')) {
                    dispatch(registerField("addPret", 'typeadherent', 'Field'));
                    props.updateField('typeadherent', pret.typeadherent ? pret.typeadherent : null)
                }
                if (pret.hasOwnProperty('numeroclient')) {
                    dispatch(registerField("addPret", 'numeroclient', 'Field'));
                    props.updateField('numeroclient', pret.numeroclient ? pret.numeroclient : null)
                }
                if (pret.hasOwnProperty('rib')) {
                    dispatch(registerField("addPret", 'rib', 'Field'));
                    props.updateField('rib', pret.rib ? pret.rib : null)
                }
                if (pret.hasOwnProperty('agence')) {
                    dispatch(registerField("addPret", 'agence', 'Field'));
                    props.updateField('agence', pret.agence ? pret.agence : null)
                }
                if (pret.hasOwnProperty('codeguichet')) {
                    dispatch(registerField("addPret", 'codeguichet', 'Field'));
                    props.updateField('codeguichet', pret.codeguichet ? pret.codeguichet : null)
                }
                if (pret.hasOwnProperty('codebanque')) {
                    dispatch(registerField("addPret", 'codebanque', 'Field'));
                    props.updateField('codebanque', pret.codebanque ? pret.codebanque : null)
                }
                if (pret.hasOwnProperty('numerocompte')) {
                    dispatch(registerField("addPret", 'numerocompte', 'Field'));
                    props.updateField('numerocompte', pret.numerocompte ? pret.numerocompte : null)
                }
                if (pret.hasOwnProperty('nomagent')) {
                    dispatch(registerField("addPret", 'nomagent', 'Field'));
                    props.updateField('nomagent', pret.nomagent ? pret.nomagent : null)
                }
                if (pret.hasOwnProperty('primeemprunteur')) {
                    dispatch(registerField("addPret", 'primeemprunteur', 'Field'));
                    props.updateField('primeemprunteur', pret.primeemprunteur ? pret.primeemprunteur : null)
                }
                if (pret.hasOwnProperty('primerisque')) {
                    dispatch(registerField("addPret", 'primerisque', 'Field'));
                    props.updateField('primerisque', pret.primerisque ? pret.primerisque : null)
                }
                if (pret.hasOwnProperty('fraisaccessoires')) {
                    dispatch(registerField("addPret", 'fraisaccessoires', 'Field'));
                    props.updateField('fraisaccessoires', pret.fraisaccessoires ? pret.fraisaccessoires : null)
                }
                if (pret.hasOwnProperty('montantsurprime')) {
                    dispatch(registerField("addPret", 'montantsurprime', 'Field'));
                    props.updateField('montantsurprime', pret.montantsurprime ? pret.montantsurprime : null)
                }
                if (pret.hasOwnProperty('primeht')) {
                    dispatch(registerField("addPret", 'primeht', 'Field'));
                    props.updateField('primeht', pret.primeht ? pret.primeht : null)
                }
                if (pret.hasOwnProperty('prime')) {
                    dispatch(registerField("addPret", 'prime', 'Field'));
                    props.updateField('prime', pret.prime ? pret.prime : null)
                }
                if (pret.hasOwnProperty('txprimedef')) {
                    dispatch(registerField("addPret", 'txprimedef', 'Field'));
                    props.updateField('txprimedef', pret.txprimedef ? pret.txprimedef : null)
                }
                if (pret.hasOwnProperty('txsurprime')) {
                    dispatch(registerField("addPret", 'txsurprime', 'Field'));
                    props.updateField('txsurprime', pret.txsurprime ? pret.txsurprime : null)
                }
                if (pret.hasOwnProperty('txprimeunique')) {
                    dispatch(registerField("addPret", 'txprimeunique', 'Field'));
                    props.updateField('txprimeunique', pret.txprimeunique ? pret.txprimeunique : null)
                }
                if (pret.hasOwnProperty('remboursement')) {
                    dispatch(registerField("addPret", 'remboursement', 'Field'));
                    props.updateField('remboursement', pret.remboursement ? pret.remboursement : null)
                }
                if (pret.hasOwnProperty('periodicite')) {
                    dispatch(registerField("addPret", 'periodicite', 'Field'));
                    props.updateField('periodicite', pret.periodicite ? pret.periodicite : null)
                }
                if (pret.hasOwnProperty('dateecheancefin')) {
                    dispatch(registerField("addPret", 'dateecheancefin', 'Field'));
                    props.updateField('dateecheancefin', pret.dateecheancefin ? pret.dateecheancefin : null)
                }
                if (pret.hasOwnProperty('dateecheancedebut')) {
                    dispatch(registerField("addPret", 'dateecheancedebut', 'Field'));
                    props.updateField('dateecheancedebut', pret.dateecheancedebut ? pret.dateecheancedebut : null)
                }
                if (pret.hasOwnProperty('effetgarantie')) {
                    dispatch(registerField("addPret", 'effetgarantie', 'Field'));
                    props.updateField('effetgarantie', pret.effetgarantie ? pret.effetgarantie : null)
                } 
                if (pret.hasOwnProperty('typepret')) {
                    dispatch(registerField("addPret", 'typepret', 'Field'));
                    props.updateField('typepret', pret.typepret ? pret.typepret : null)
                }
                
                
                if (pret.hasOwnProperty('racheterpar')) {
                    dispatch(registerField("addPret", 'racheterpar', 'Field'));
                    props.updateField('racheterpar', pret.racheterpar ? pret.racheterpar : null)
                }
                if (pret.hasOwnProperty('daterachat')) {
                    dispatch(registerField("addPret", 'daterachat', 'Field'));
                    props.updateField('daterachat', pret.daterachat ? pret.daterachat : null)
                }
                if (pret.hasOwnProperty('motifrachat')) {
                    dispatch(registerField("addPret", 'motifrachat', 'Field'));
                    props.updateField('motifrachat', pret.motifrachat ? pret.motifrachat : null)
                }                
                  
                if (pret.hasOwnProperty('sportpratique')) {
                    dispatch(registerField("addPret", 'sportPratique', 'Field'));
                    props.updateField('sportPratique', pret.sportpratique ? pret.sportpratique : null)
                }
                if (pret.hasOwnProperty('estsportif')) {
                    dispatch(registerField("addPret", 'estSportif', 'Field'));
                    props.updateField('estSportif', pret.estsportif ? pret.estsportif : null)
                }
                if (pret.hasOwnProperty('datecausetravail')) {
                    dispatch(registerField("addPret", 'dateCauseTravail', 'Field'));
                    props.updateField('dateCauseTravail', pret.datecausetravail ? pret.datecausetravail : null)
                }
                if (pret.hasOwnProperty('causearrettravail')) {
                    dispatch(registerField("addPret", 'causeArretTravail', 'Field'));
                    props.updateField('causeArretTravail', pret.causearrettravail ? pret.causearrettravail : null)
                }
                if (pret.hasOwnProperty('datereprisetravail')) {
                    dispatch(registerField("addPret", 'repriseArretTravail', 'Field'));
                    props.updateField('repriseArretTravail', pret.datereprisetravail ? pret.datereprisetravail : null)
                }
                if (pret.hasOwnProperty('motifarret')) {
                    dispatch(registerField("addPret", 'motifArretTravail', 'Field'));
                    props.updateField('motifArretTravail', pret.motifarret ? pret.motifarret : null)
                }
                if (pret.hasOwnProperty('datearrettravail')) {
                    dispatch(registerField("addPret", 'dureeArretTravail', 'Field'));
                    props.updateField('dureeArretTravail', pret.datearrettravail ? pret.datearrettravail : null)
                }
                if (pret.hasOwnProperty('estenarrettravail')) {
                    dispatch(registerField("addPret", 'estEnArretTravail', 'Field'));
                    props.updateField('estEnArretTravail', pret.estenarrettravail ? pret.estenarrettravail : null)
                }
                if (pret.hasOwnProperty('natureinfirmite')) {
                    dispatch(registerField("addPret", 'natureInfirmite', 'Field'));
                    props.updateField('natureInfirmite', pret.natureinfirmite ? pret.natureinfirmite : null)
                }
                if (pret.hasOwnProperty('estinfirme')) {
                    dispatch(registerField("addPret", 'estInfirme', 'Field'));
                    props.updateField('estInfirme', pret.estinfirme ? pret.estinfirme : null)
                }
                if (pret.hasOwnProperty('distraction')) {
                    dispatch(registerField("addPret", 'distraction', 'Field'));
                    props.updateField('distraction', pret.distraction ? pret.distraction : null)
                }
                if (pret.hasOwnProperty('buvezvous')) {
                    dispatch(registerField("addPret", 'buvezVous', 'Field'));
                    props.updateField('buvezVous', pret.buvezvous ? pret.buvezvous : null)
                }
                if (pret.hasOwnProperty('nbcigarettejour')) {
                    dispatch(registerField("addPret", 'nbCigaretteJour', 'Field'));
                    props.updateField('nbCigaretteJour', pret.nbcigarettejour ? pret.nbcigarettejour : null)
                }
                if (pret.hasOwnProperty('fumezvous')) {
                    dispatch(registerField("addPret", 'fumezVous', 'Field'));
                    props.updateField('fumezVous', pret.fumezvous ? pret.fumezvous : null)
                }
                if (pret.hasOwnProperty('tension')) {
                    
                    const tention =  pret.tension.split(';')
                    if(tention && tention.length!==0){
                        dispatch(registerField("addPret", 'systolique', 'Field'));
                        dispatch(registerField("addPret", 'diastolique', 'Field'));
                        props.updateField('systolique', tention[0])
                        props.updateField('diastolique', tention[1])
                    }
                    
                }
                if (pret.hasOwnProperty('poids')) {
                    dispatch(registerField("addPret", 'poids', 'Field'));
                    props.updateField('poids', pret.poids ? pret.poids : null)
                }
                if (pret.hasOwnProperty('taille')) {
                    dispatch(registerField("addPret", 'taille', 'Field'));
                    props.updateField('taille', pret.taille ? pret.taille : null)
                }
                
                if (pret.hasOwnProperty('encotation')) {
                    dispatch(registerField("addPret", 'encotation', 'Field'));
                    props.updateField('encotation', pret.encotation ? pret.encotation : null)
                }
                if (pret.hasOwnProperty('motifcotation')) {
                    dispatch(registerField("addPret", 'motifcotation', 'Field'));
                    props.updateField('motifcotation', pret.motifcotation ? pret.motifcotation : null)
                }

                if (pret.hasOwnProperty('etat')) {
                    dispatch(registerField("addPret", 'etat', 'Field'));
                    props.updateField('etat', pret.etat ? pret.etat : null)
                }
                if (pret.hasOwnProperty('moftifrejet')) {
                    dispatch(registerField("addPret", 'moftifrejet', 'Field'));
                    props.updateField('moftifrejet', pret.moftifrejet ? pret.moftifrejet : null)
                }
                if (pret.hasOwnProperty('rejeterpar')) {
                    dispatch(registerField("addPret", 'rejeterpar', 'Field'));
                    props.updateField('rejeterpar', pret.rejeterpar ? pret.rejeterpar : null)
                }
                if (pret.hasOwnProperty('daterejet')) {
                    dispatch(registerField("addPret", 'daterejet', 'Field'));
                    props.updateField('daterejet', pret.daterejet ? pret.daterejet : null)
                }
                if (pret.hasOwnProperty('referencepret')) {
                    dispatch(registerField("addPret", 'referencepret', 'Field'));
                    props.updateField('referencepret', pret.referencepret ? pret.referencepret : null)
                }
                if (pret.hasOwnProperty('misenplacepar')) {
                    dispatch(registerField("addPret", 'misenplacepar', 'Field'));
                    props.updateField('misenplacepar', pret.misenplacepar ? pret.misenplacepar : null)
                }
                if (pret.hasOwnProperty('datemiseenplace')) {
                    dispatch(registerField("addPret", 'datemiseenplace', 'Field'));
                    props.updateField('datemiseenplace', pret.datemiseenplace ? pret.datemiseenplace : null)
                }

                if (pret.hasOwnProperty('beneficiaireaudeces')) {
                    dispatch(registerField("addPret", 'beneficiaireaudeces', 'Field'));
                    props.updateField('beneficiaireaudeces', pret.beneficiaireaudeces ? pret.beneficiaireaudeces.split(";") : null)
                }
                if(pret.hasOwnProperty('typeadherent') && pret.typeadherent ==='Groupe'  && pret.hasOwnProperty('adherentconex') ){
                    dispatch(registerField("addPret", 'adherentconex', 'Field'));
                    props.updateField('adherentconex',[...pret.adherentconex])
                }
                if(pret.hasOwnProperty('diastolique')){
                    dispatch(registerField("addPret", 'diastolique', 'Field'));
                    props.updateField('diastolique',[...pret.diastolique])
                }
                if(pret.hasOwnProperty('systolique')){
                    dispatch(registerField("addPret", 'systolique', 'Field'));
                    props.updateField('systolique',[...pret.systolique])
                }
            }
            //informations bénéficaires
            if (beneficiaires) {
                const benData = []
                if (Array.isArray(beneficiaires)) {
                    beneficiaires.map((ben, ix) => {
                        benData.push({
                            id:ben.id,
                            nomBeneficiaire: ben.nom,
                            prenomBeneficiaire: ben.prenom,
                            dateNaissanceBeneficiaire: ben.datenaissance,
                            lieuNaissanceBeneficiaire: ben.lieunaissance,
                            lieuResidenceBeneficiaire: ben.lieuresidence,
                            filiationBeneficiaire: 'Moi même',
                            emailBeneficiaire: ben.email,
                            telephoneBeneficiaire: ben.mobile,
                            taux: '100',
                            monIndex: ben.id,
                            type: ben.type
                        })
                    })
                    if (benData && benData.length !== 0) {
                        if (!pret.hasOwnProperty('beneficiaires')) {
                            dispatch(registerField("addPret", 'beneficiaires', 'Field'));
                            props.updateField('beneficiaires', benData)
                        }
                    }

                } else {
                    benData.push({
                        id:beneficiaires.id,
                        nomBeneficiaire: beneficiaires.nom,
                        prenomBeneficiaire: beneficiaires.prenom,
                        dateNaissanceBeneficiaire: beneficiaires.datenaissance,
                        lieuNaissanceBeneficiaire: beneficiaires.lieunaissance,
                        lieuResidenceBeneficiaire: beneficiaires.lieuresidence,
                        filiationBeneficiaire: 'Moi même',
                        emailBeneficiaire: beneficiaires.email,
                        telephoneBeneficiaire: beneficiaires.mobile,
                        taux: '100',
                        monIndex: beneficiaires.id,
                        type: beneficiaires.type
                    })
                    if (benData && benData.length !== 0) {
                        if (!pret.hasOwnProperty('beneficiaires')) {
                            dispatch(registerField("addPret", 'beneficiaires', 'Field'));
                            props.updateField('beneficiaires', benData)
                        }

                    }
                }
            }
            //information questionnaires
    
            if (questionnaire && questionnaire.length !== 0) {
                const newQuestionnaire = questionnaire.filter(item=>(item.codeparent !=='null' && !item.codeparent))
                
                newQuestionnaire?.forEach((q, ix) => {
                   
                    if (!pret.hasOwnProperty(`nature-${q.codequestion}`)) {
                        dispatch(registerField("addPret", `nature-${q.codequestion}`, 'Field'));
                        props.updateField(`nature-${q.codequestion}`, q['reponse'] || "")
                    }

                    if (!pret.hasOwnProperty(`valeurNature-${q.codequestion}`)) {
                        dispatch(registerField("addPret", `valeurNature-${q.codequestion}`, 'Field'));
                        props.updateField(`valeurNature-${q.codequestion}`, q['reponse'] || "")
                    }

                    if (!pret.hasOwnProperty(`periodetrt-${q.codequestion}`)) {
                        dispatch(registerField("addPret", `periodetrt-${q.codequestion}`, 'Field'));
                        props.updateField(`periodetrt-${q.codequestion}`, q['reponse'] || "")
                    }

                    if (!pret.hasOwnProperty(`lieu-${q.codequestion}`)) {
                        dispatch(registerField("addPret", `lieu-${q.codequestion}`, 'Field'));
                        props.updateField(`lieu-${q.codequestion}`, q['lieuTraitement'] || "")
                    }

                })
            }
        }
    },[dataForm.mode])

    useEffect(() => {
        displayDataMode()
    },[dataForm.mode])

    const resetForm =()=>{
        props.dispatch(initialize('addPret'))
        history.push(`/${layoutRoot}/epret`, {fonction:'SIMUL'});
      }
    //alerte message
    const [open, setOpen] = React.useState(false);
    const handleClick = () => {
        setOpen(true);
    };
    const handleClose = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }
        setOpen(false);
    };
    const [alertinfo, setAlertinfo] = useReducer((state, newState) => ({ ...state, ...newState }),
        {
            message: '',
            mode: 'warning',
        });
        
    const handleAlert = (message, mode) => {
        if (!message || !mode) { return }
        setAlertinfo({
            ['message']: message,
            ['mode']: mode
        })
        handleClick()
    }

    const [succes, setSucces] = useState(false)
    //questionnaire
    const questionnairesField = {
        columns: [
            { title: '#', field: 'id', hidden: true },
            { title: 'Nature', field: 'nature' },
            {
                title: 'Réponse', field: 'reponse', render: rowData => (
                    <Field
                        component={CustomRadio}
                        id={`nature-${rowData.id}`}
                        name={`nature-${rowData.id}`}
                        formControlProps={{
                            fullWidth: true
                        }}
                        options={[{ "label": "Oui", "value": "Oui" }, { "label": "Non", "value": "Non" }]}
                        row
                    />
                )
            },
            {
                title: 'Précisez la nature', field: 'valeurNature', render: rowData => (
                    <Field
                        component={CustomInput}
                        id={`valeurNature-${rowData.id}`}
                        name={`valeurNature-${rowData.id}`}
                        formControlProps={{
                            fullWidth: false
                        }}
                        variant="outlined"
                    />
                )
            },
            {
                title: 'Date ou période de traitement', field: 'periode', render: rowData => (
                    <Field
                        component={CustomInput}
                        id={`periodetrt${rowData.id}`}
                        name={`periodetrt${rowData.id}`}
                        formControlProps={{
                            fullWidth: true
                        }}
                        type='text'
                        variant="outlined"
                    />
                )
            },
            {
                title: 'Lieu de traitement', field: 'lieu', render: rowData => (
                    <Field
                        component={CustomInput}
                        id={`lieu-${rowData.id}`}
                        name={`lieu-${rowData.id}`}
                        formControlProps={{
                            fullWidth: false
                        }}
                        variant='outlined'
                    />
                )
            },
        ], data: [
            { id: 1, nature: 'Avez-vous déjà été victime d\'un accident', reponse: '', valeurNature: '', periode: '', lieu: '', details: ["Quelle est la nature de l'accident ?", "Date de l'accident ?", "Lieu de l'accident"] },
            { id: 2, nature: 'Suivez-vous ou avez-vous suivi un traitement médical ces 6(six) derniers mois ?', reponse: '', valeurNature: '', periode: '', lieu: '', details: ['Quelle est la nature du traitement ?', 'Date du traitement ?', 'Lieu du traitement'] },
            { id: 3, nature: 'Avez-vous déjà subi une transfusion de sang?', reponse: '', valeurNature: '', periode: '', lieu: '', details: ["Avez-vous fait récemment l\'objet d\'un test de dépistage de l\'hépatite B?"] },
            { id: 4, nature: 'Avez-vous déjà subi des interventions chirurgicales?', reponse: '', valeurNature: '', periode: '', lieu: '', details: [] },
            { id: 5, nature: 'Devez-vous subir une intervention chirurgicale?', reponse: '', valeurNature: '', periode: '', lieu: '', details: ['Quelle est la nature de l\'intervention ?', 'Date de l\'intervention', 'Lieu de l\'intervention'] },
            { id: 6, nature: 'Etes-vous sous traitement médical pour, ou souffrez-vous d\'une ces maladies ?', reponse: '', valeurNature: '', periode: '', lieu: '' },
            { id: 7, nature: 'Diabète', reponse: '', valeurNature: '', periode: '', lieu: '', details: ['Suivez-vous un traitement', 'Quel est le type de diabète ?', 'Le diagnostic de cette maladie date de quand ?', 'Votre dernière consultation chez le médecin date de quand ?', 'A combien était la dernière prise'] },
            { id: 8, nature: 'Hypertension artérielle', reponse: '', valeurNature: '', periode: '', lieu: '', details: ['Suivez-vous un traitement ?', 'Le diagnostic de cette maladie date de quand ?', 'Votre dernière consultation chez le médecin date de quand ?', 'A combien était la dernière prise'] },
            { id: 9, nature: 'Drépanocytose', reponse: '', valeurNature: '', periode: '', lieu: '', details: ['Suivez-vous un traitement ?', 'Le diagnostic de cette maladie date de quand ?', 'Votre dernière consultation chez le médecin date de quand ?'] },
            { id: 10, nature: 'Cirrhose de foie', reponse: '', valeurNature: '', periode: '', lieu: '', details: [] },
            { id: 11, nature: 'Affections pulmonaires', reponse: '', valeurNature: '', periode: '', lieu: '', details: ['Suivez-vous un traitement ?', 'Le diagnostic de cette maladie date de quand ?', 'Votre dernière consultation chez le médecin date de quand ?'] },
            { id: 12, nature: 'Cancer', reponse: '', valeurNature: '', periode: '', lieu: '', details: [] },
            { id: 13, nature: 'Anémie', reponse: '', valeurNature: '', periode: '', lieu: '', details: [] },
            { id: 14, nature: 'Insuffisance rénale', reponse: '', valeurNature: '', periode: '', lieu: '', details: [] },
            { id: 15, nature: 'Avez-vous déjà été victime d’un AVC ?', reponse: '', valeurNature: '', periode: '', lieu: '', details: [] },
            
        ]
    }

    const handleCalculPrime = async (values) => {
       
        let mesDonnees
        mesDonnees = JSON.stringify(values, null, 2)
        mesDonnees = JSON.parse(mesDonnees)
        //déclaration
        let indice, tauxIndice, tauxInteretUnitaire, tauxConversion, primeHt, dureeCalcul,primeEmprunteur
        let params
        let maDuree = parseInt(mesDonnees.duree.toString().replace(/ /g, ''))
        let tauxTrouve
        let examenExiger
        let examenSup = false
        let montantSurprime = 0
        let montantOptYk = 0
        let maperiodicite
        let myTypePret
        let motifCotation = []
        let tauxSurpIndice, tauxSurpTension, tauxSurpDiabete
        let maPrime = 0
        {/*vérifié date de naissance et durée*/}
        if (!mesDonnees.hasOwnProperty('datenaissance') || !mesDonnees.hasOwnProperty('duree')) {
            return
        }
        if (dataForm && dataForm.hasOwnProperty('civiliteSous')) {
            const sexead = (dataForm.civiliteSous === 'M') ? "M" : "F"
            if (!dataForm.hasOwnProperty('sexe')) {
                props.dispatch(registerField("addPret", 'sexe', 'Field'));
            }
            props.updateField('sexe', sexead)
        }
        //Evaluation de la prime yako 
        
        if (mesDonnees.hasOwnProperty('duree') && mesDonnees.hasOwnProperty('datenaissance') && dayjs(datenaissance.toString()).isValid() && (mesDonnees.hasOwnProperty('unableyako') && !mesDonnees.unableyako)) {
           
            if (!mesDonnees.hasOwnProperty('capitalSouscrit')) {
                props.dispatch(registerField("addPret", 'capitalSouscrit', 'Field'));
                props.updateField('capitalSouscrit', 1000000)
            }
            
            if(['PAMFCI','COFINA'].includes(credentials.codepartenaire)){
                if(!mesDonnees.hasOwnProperty('primeobseque')){
                    props.dispatch(registerField("addPret",'primeobseque','Field'));
                }
                props.updateField('primeobseque',5000)
                montantOptYk=5000
            }else{
                evaluationPret(ageAssure(datenaissance), 1000000, duree, credentials.codepartenaire,mesDonnees.periodicite).then(p => {
                    if (p) {
                        montantOptYk = p
                        if (!mesDonnees.hasOwnProperty('primeobseque')) {
                            props.dispatch(registerField("addPret", 'primeobseque', 'Field'));
                        }
                        props.updateField('primeobseque', (!isNaN(montantOptYk) || montantOptYk) ? Math.round(Number(montantOptYk)) : 0)
                    }
                })
            }
        }

        //selection des examen 
        if (mesDonnees.datenaissance && dayjs(datenaissance.toString()).isValid() && mesDonnees.montantpret && mesDonnees.duree) {
            const exparam = {
                montantpret: mesDonnees.montantpret ? parseInt(mesDonnees.montantpret.toString().replace(/ /g, '')) : 0,
                age: ageAssure(mesDonnees.datenaissance),
                duree: mesDonnees.duree
            }
            examenExiger = await getExamenExiger(exparam)
           
            if (examenExiger && examenExiger.length !== 0) {
                let mesExams = ""
                examenExiger.map(exam => { mesExams = `${exam.examens}` })
                if (!mesDonnees.hasOwnProperty('examens')) {
                    props.dispatch(registerField("addPret", 'examens', 'Field'));
                }
                props.updateField('examens', mesExams)

                examenExiger.forEach(element => {
                    if (element.examens !== 'QM') {
                        examenSup = true
                        if (!mesDonnees.hasOwnProperty('examensup')) {
                            props.dispatch(registerField("addPret", 'examensup', 'Field'));
                        }
                        props.updateField('examensup', true)
                        return;
                    }
                });
            }
        }
        
        //determination de la durée de calcul
        if (mesDonnees.hasOwnProperty('datenaissance') && dayjs(datenaissance.toString()).isValid()) {

            if (credentials.codepartenaire === "WF" || credentials.codepartenaire === "092" || credentials.codepartenaire === "PAMFCI" || credentials.codepartenaire === "COFINA") {
                if (credentials.codepartenaire === "092") {
                    dureeCalcul = 84
                    maperiodicite = mesDonnees.hasOwnProperty('remboursement') && mesDonnees.remboursement
                    myTypePret = mesDonnees.hasOwnProperty("typepret") ? mesDonnees.typepret : null
                } else if(["PAMFCI","COFINA"].includes(credentials.codepartenaire)) {
                    dureeCalcul = maDuree
                    maperiodicite = (mesDonnees.hasOwnProperty('remboursement') && mesDonnees.remboursement === 'Unique') ? 'Unique' : 'All'
                    myTypePret = myTypePret = mesDonnees.hasOwnProperty("typepret") ? mesDonnees.typepret : null
                }else{
                    dureeCalcul = 99
                    maperiodicite = (mesDonnees.hasOwnProperty('remboursement') && mesDonnees.remboursement === 'Unique') ? 'Unique' : 'All'
                    myTypePret = myTypePret = mesDonnees.hasOwnProperty("typepret") ? mesDonnees.typepret : null  
                }

                params = {
                    age: ageAssure(mesDonnees.datenaissance),
                    duree: dureeCalcul,
                    periodicite: maperiodicite,
                    partenaire: credentials.codepartenaire,
                    typePret: myTypePret
                }
               
                tauxTrouve = await getTauxInteret(params)
             
                tauxInteretUnitaire = (tauxTrouve && tauxTrouve.length !== 0) ? (Number(tauxTrouve[0].tauxprimeunique)) : 0
               
            } else {
                maperiodicite = mesDonnees.hasOwnProperty("periodicite") ? mesDonnees.periodicite : ""
                myTypePret = ""
                if (maDuree <= 12) {
                    dureeCalcul = ['PAMFCI','COFINA'].includes(credentials.codepartenaire) ? maDuree:1
                    tauxTrouve = await getTauxInteret(params)
                    tauxInteretUnitaire = (tauxTrouve && tauxTrouve.length !== 0) && ((maDuree <= 6) ? (parseFloat(tauxTrouve[0].tauxprimeunique) / 2) : parseFloat(tauxTrouve[0].tauxprimeunique))
                } else {
                    if ((maDuree % 12) === 0) {
                        dureeCalcul =['PAMFCI','COFINA'].includes(credentials.codepartenaire) ? maDuree: (maDuree / 12)
                        params = {
                            age: ageAssure(mesDonnees.datenaissance),
                            duree: dureeCalcul,
                            periodicite: mesDonnees.periodicite,
                            partenaire: credentials.codepartenaire,
                            typePret: myTypePret
                        }
                       
                        tauxTrouve = await getTauxInteret(params)
                        tauxInteretUnitaire = (tauxTrouve && tauxTrouve.length !== 0) && parseFloat(tauxTrouve[0].tauxprimeunique)
                    } else {
                        const dureMin = parseInt((maDuree / 12))
                        const dureMax = parseInt((maDuree / 12)) + 1
                        //taux min
                        params = {
                            age: ageAssure(mesDonnees.datenaissance),
                            duree: dureMin,
                            periodicite: mesDonnees.periodicite,
                            partenaire: credentials.codepartenaire,
                            typePret: myTypePret
                        }
                        const tauxMin = await getTauxInteret(params)
                        //taux max
                        params = {
                            age: ageAssure(mesDonnees.datenaissance),
                            duree: dureMax,
                            periodicite: mesDonnees.periodicite,
                            partenaire: credentials.codepartenaire,
                            typePret: myTypePret
                        }

                        const tauxMax = await getTauxInteret(params)
                        if (tauxMin.length !== 0 && tauxMax.length !== 0) {
                            if ((maDuree % 12) <= 6) { tauxInteretUnitaire = (parseFloat(tauxMin[0].tauxprimeunique) + parseFloat(tauxMax[0].tauxprimeunique)) / 2 } else { tauxInteretUnitaire = parseFloat(tauxMax[0].tauxprimeunique) }
                        }
                    }
                }
            }
        }

        //determination du taux unitaire
          
        if (tauxInteretUnitaire && tauxInteretUnitaire !== undefined) {
            primeHt = (parseInt(mesDonnees.montantpret.toString().replace(/ /g, '')) * (Number(tauxInteretUnitaire) / 100))
            if (!mesDonnees.hasOwnProperty('txPrimeUnique')) {
                props.dispatch(registerField("addPret", 'txPrimeUnique', 'Field'));
            }
            props.updateField('txPrimeUnique', (Number(tauxInteretUnitaire) * 100).toFixed(4))

            if (!mesDonnees.hasOwnProperty('primeht')) {
                props.dispatch(registerField("addPret", 'primeht', 'Field'));
            }
            props.updateField('primeht', primeHt)
        } else {
            motifCotation.push("Aucun taux d'intérêt défini")
            if (!mesDonnees.hasOwnProperty('txPrimeUnique')) {
                props.dispatch(registerField("addPret", 'txPrimeUnique', 'Field'));
            }
            props.updateField('txPrimeUnique', 0)
        }

        if(credentials.codepartenaire == "PAMFCI" && mesDonnees.hasOwnProperty('montantpret')){
            if(!mesDonnees.hasOwnProperty('primerisque')){
                props.dispatch(registerField("addPret",'primerisque','Field'));
            }
            props.updateField('primerisque',( typeof mesDonnees?.montantpret==='string' ? parseInt(mesDonnees?.montantpret?.replace(/ /g, ''))*(0.5/100) : parseInt(mesDonnees?.montantpret)*(0.5/100)))
        }else{
            if(!mesDonnees.hasOwnProperty('primerisque')){
                props.dispatch(registerField("addPret",'primerisque','Field'));
            }
            props.updateField('primerisque',0)
        }

        if ((mesDonnees.hasOwnProperty('datenaissance') && (ageAssure(mesDonnees.datenaissance) > 70) || (parseInt(maDuree) / 12) > 15)) {
            examenSup =true
            if (!mesDonnees.hasOwnProperty('examensup')) {
                props.dispatch(registerField("addPret", 'examensup', 'Field'));
            }
            props.updateField('examensup', true)
        }

        // pret est-il conventionnel?
        if (['WF','092','PAMFCI','COFINA'].includes(credentials.codepartenaire)) {
            const ageAd = ageAssure(mesDonnees.datenaissance)
            if (!mesDonnees.hasOwnProperty('examensup')) {
                props.dispatch(registerField("addPret", 'examensup', 'Field'));
                props.updateField('examensup', false)
            }
            if (mesDonnees.hasOwnProperty('montantpret') && parseInt(mesDonnees.montantpret.toString().replace(/ /g, '')) > 30000000) {
                //props.updateField('examensup', true)
                motifCotation.push("Capital non conventionnel")
            }
            if (ageAd < 18 || ageAd > 65) {
                examenSup =true
                if (!mesDonnees.hasOwnProperty('examensup')) {
                    props.dispatch(registerField("addPret", 'examensup', 'Field'));
                }
                props.updateField('examensup', true)
                motifCotation.push("Age non conventionnel")
            }
        }

        //DETERMINATION DES SURPRIME

        //calcl de l'indice de masse coorporelle
        if (mesDonnees.hasOwnProperty('datenaissance') && mesDonnees.hasOwnProperty('sexe') && mesDonnees.hasOwnProperty('poids') && mesDonnees.hasOwnProperty('taille') && mesDonnees.hasOwnProperty('dateEcheanceDebut')) {
            //dermination du pois corrigee
            const ageAd = ageBorne(mesDonnees.datenaissance, mesDonnees.dateEcheanceDebut)
            const poidsCorrige = (mesDonnees.sexe = 'F' && ageAd < 25) ? (parseInt(mesDonnees.poids) + 6) : (mesDonnees.sexe = 'M' && ageAd < 25) ? (parseInt(mesDonnees.poids) + 4) : parseInt(mesDonnees.poids)
            const tailleCorrige = parseInt((mesDonnees.taille * 1000).toString().substring(0, 3))
            indice = ((parseFloat(poidsCorrige) / (parseFloat(tailleCorrige) - 100)) - 1).toFixed(2)

            tauxIndice = await getTauxIndiceMasse({ indice })

            if (tauxIndice && parseInt(tauxIndice[0].taux) === 99) {
                if (!mesDonnees.hasOwnProperty('tarificationmedicale')) {
                    props.dispatch(registerField("addPret", 'tarificationmedicale', 'Field'));
                }
                props.updateField('tarificationmedicale', true)
                examenSup =true
                if (!mesDonnees.hasOwnProperty('examensup')) {
                    props.dispatch(registerField("addPret", 'examensup', 'Field'));
                }
                props.updateField('examensup', true)
                motifCotation.push("Indice de masse corporelle importante")
            }
        }
        //selection du taux de surmortalité      
        if (mesDonnees.hasOwnProperty('datenaissance') && mesDonnees.hasOwnProperty('dateEcheanceDebut')) {
            params = {
                ageSouscription: ageBorne(mesDonnees.datenaissance, mesDonnees.dateEcheanceDebut),
                ageEcheance: (parseInt(ageBorne(mesDonnees.datenaissance, mesDonnees.dateEcheanceDebut)) + Math.round((mesDonnees.duree / 12)))
            }
            tauxConversion = await getTauxSurmortalite(params)

            if (tauxIndice && Number(tauxIndice[0].taux) === 99) { examenSup = true }
            //calcul de taux de surprime  sur l'indice de masse corporelle
            const tauxConvert = (tauxConversion && tauxConversion.length !== 0) ? tauxConversion[0].taux : 80
            tauxSurpIndice = (tauxIndice && Number(tauxIndice[0].taux) !== 99) ? (Number(tauxIndice[0].taux) * Number(tauxConvert)) : 0
           if(tauxSurpIndice !==0){
            motifCotation.push("Indice de masse corporelle trop élevé")
           }
            //determination des taux de surprime pour la tension arterielle
            if (mesDonnees.hasOwnProperty('diastolique') && mesDonnees.hasOwnProperty('systolique')) {
                params = {
                    ageadherent: ageBorne(mesDonnees.datenaissance, mesDonnees.dateEcheanceDebut),
                    diastolique: mesDonnees.diastolique,
                    systolique: mesDonnees.systolique
                }
                const tauxTension = await getTauxTension(params)
                if (tauxTension && parseInt(tauxTension.taux) === 99) {
                    if (!mesDonnees.hasOwnProperty('tarificationmedicale')) {
                        props.dispatch(registerField("addPret", 'tarificationmedicale', 'Field'));
                    }
                    props.updateField('tarificationmedicale', true)
                    if (!mesDonnees.hasOwnProperty('examensup')) {
                        props.dispatch(registerField("addPret", 'examensup', 'Field'));
                    }
                    props.updateField('examensup', true)
                    examenSup =true
                    motifCotation.push("Taux de tension important")
                } else {
                    tauxSurpTension = (tauxTension && Number(tauxTension.taux) !== 99) ? (Number(tauxTension.taux) * Number(tauxConvert)) : 0
                }
            }

            //determination des taux de surprime pour le diabete
            if (mesDonnees.hasOwnProperty('nature-8') && mesDonnees.hasOwnProperty('nature-8-2') && mesDonnees.hasOwnProperty('nature-8-3')) {
                params = {
                    ageadherent: ageBorne(mesDonnees.datenaissance, mesDonnees.dateEcheanceDebut),
                    dureediag: (parseInt(dayjs(mesDonnees.dateEcheanceDebut).format('YYYY')) - parseInt(mesDonnees['nature-8-3'])),
                    type: mesDonnees['nature-8-2']
                }
                const tauxDiabete = await getTauxDiabete(params)
                if (tauxDiabete && parseInt(tauxDiabete.taux) === 99) {
                    if (!mesDonnees.hasOwnProperty('tarificationmedicale')) {
                        props.dispatch(registerField("addPret", 'tarificationmedicale', 'Field'));
                    }
                    props.updateField('tarificationmedicale', true)
                    //props.updateField('examensup', true)
                    motifCotation.push("Taux de diabète important")
                } else {
                    tauxSurpDiabete = (tauxDiabete && Number(tauxDiabete.taux) !== 99) ? (Number(tauxDiabete.taux) * Number(tauxConvert)) : 0
                }
            }
        }

        //verifier questionnaires 
        if(examenSup){
            if (!mesDonnees.hasOwnProperty('examensup')) {
                props.dispatch(registerField("addPret", 'examensup', 'Field'));
                props.updateField('examensup', true)
            }
        }else{
            if (!mesDonnees.hasOwnProperty('examensup')) {
                props.dispatch(registerField("addPret", 'examensup', 'Field'));
                props.updateField('examensup', false)
            }
        }
        
        if (mesDonnees.hasOwnProperty('nature-7') && mesDonnees['nature-7'] === 'Oui') {
            props.updateField('examensup', true)
            motifCotation.push("Est sous traitement médical pour, ou souffre de Diabète")
        }
        if (mesDonnees.hasOwnProperty('nature-8') && mesDonnees['nature-8'] === 'Oui') {
            props.updateField('examensup', true)
            motifCotation.push("Est sous traitement médical pour, ou souffre d'Hypertension artérielle")
        }
        if (mesDonnees.hasOwnProperty('nature-9') && mesDonnees['nature-9'] === 'Oui') {
            props.updateField('examensup', true)
            motifCotation.push("Est sous traitement médical pour, ou souffre de Drépanocytose")
        }
        if (mesDonnees.hasOwnProperty('nature-10') && mesDonnees['nature-10'] === 'Oui') {
            props.updateField('examensup', true)
            motifCotation.push("Est atteint(e) d'une cirrhose de foie")
        }
        if (mesDonnees.hasOwnProperty('nature-11') && mesDonnees['nature-11'] === 'Oui') {
            props.updateField('examensup', true)
            motifCotation.push("Est sous traitement médical pour, ou souffre d'Affections pulmonaires")
        }
        if (mesDonnees.hasOwnProperty('nature-12') && mesDonnees['nature-12'] === 'Oui') {
            props.updateField('examensup', true)
            motifCotation.push("Est sous traitement médical pour, ou souffre de Cancer")
        }
        if (mesDonnees.hasOwnProperty('nature-13') && mesDonnees['nature-13'] === 'Oui') {
            props.updateField('examensup', true)
            motifCotation.push("Est sous traitement médical pour, ou souffre d'Anémie")
        }
        if (mesDonnees.hasOwnProperty('nature-14') && mesDonnees['nature-14'] === 'Oui') {
            props.updateField('examensup', true)
            motifCotation.push("Est sous traitement médical pour, ou souffre d'Insuffisance rénale")
        }
        if (mesDonnees.hasOwnProperty('nature-15') && mesDonnees['nature-15'] === 'Oui') {
            props.updateField('examensup', true)
            motifCotation.push("Est sous traitement médical pour, ou a déjà été victime d’un AVC")
        }
        

        if (!mesDonnees.hasOwnProperty('motifcotation')) {
            props.dispatch(registerField("addPret", 'motifcotation', 'Field'));
            props.updateField('motifcotation', motifCotation)
        } else {
            props.updateField('motifcotation', motifCotation)
        }

        if (mesDonnees.hasOwnProperty('duree') && mesDonnees.hasOwnProperty('montantpret')) {
            let fraisAccesoire = 0
            if ([ "WF","092","PAMFCI","COFINA"].includes(credentials.codepartenaire)) {
                fraisAccesoire = (parseInt(mesDonnees.montantpret.toString().replace(/ /g, '')) <= 30000000) ? 0 : 0
            } else {
                fraisAccesoire = (parseInt(mesDonnees.montantpret.toString().replace(/ /g, '')) <= 5000000) ? 5000 : 10000
            }

            //calcul montant de surprime
            //surprime indice de max
            const montantSurpIndice = (primeHt && tauxSurpIndice) ? (tauxSurpIndice * primeHt) : 0
            const montantSurpTension = (primeHt && tauxSurpTension) ? (tauxSurpTension * primeHt) : 0
            const montantSurpDiabete = (primeHt && tauxSurpDiabete) ? (tauxSurpDiabete * primeHt) : 0
            montantSurprime = (montantSurpIndice + montantSurpTension + montantSurpDiabete)

            if (!mesDonnees.hasOwnProperty('montantsurprime')) {
                props.dispatch(registerField("addPret", 'montantsurprime', 'Field'));
            }
            props.updateField('montantsurprime', (!isNaN(montantSurprime) || montantSurprime) ? Math.round(montantSurprime) : "")
            // calcul de la prime TTC
            if ([ "WF","092","PAMFCI","COFINA"].includes(credentials.codepartenaire)) {
                primeEmprunteur = Math.round(primeHt + montantSurprime + fraisAccesoire + montantOptYk)
                if(mesDonnees && mesDonnees.typeadherent=='Groupe'){
                    maPrime = Math.round(primeHt + montantSurprime + fraisAccesoire + (montantOptYk*parseInt(mesDonnees.nbadherent)) + primerisque)
                }else{
                    console.log(primeHt,montantSurprime,fraisAccesoire,montantOptYk,primerisque);
                    maPrime = Math.round(primeHt + montantSurprime + fraisAccesoire + montantOptYk + primerisque)
                }
               
            }else{
                if(mesDonnees && mesDonnees.typeadherent=='Groupe'){
                    maPrime = Math.round(primeHt + montantSurprime + (montantOptYk*parseInt(mesDonnees.nbadherent)))
                }else{
                    maPrime = Math.round(primeHt + montantSurprime + montantOptYk)
                }
                
            }
          
         
            if (!mesDonnees.hasOwnProperty('prime')) {
                props.dispatch(registerField("addPret", 'prime', 'Field'));
            }
            console.log(maPrime);
            props.updateField('prime', (!isNaN(maPrime) || maPrime) ? maPrime : "")

            if (!mesDonnees.hasOwnProperty('primeemprunteur')) {
                props.dispatch(registerField("addPret", 'primeemprunteur', 'Field'));
            }
            props.updateField('primeemprunteur', (!isNaN(primeEmprunteur) || primeEmprunteur) ? primeEmprunteur : "")

            if (!mesDonnees.hasOwnProperty('fraisaccessoires')) {
                props.dispatch(registerField("addPret", 'fraisaccessoires', 'Field'));
            }
            props.updateField('fraisaccessoires', fraisAccesoire ? fraisAccesoire : "")
        }
    }

    const [activeStep, setActiveStep] = React.useState(0);
    const steps = getSteps();

    const handleNext = () => {
        if (activeStep === 1 || activeStep === 2 || activeStep === 4) {
            handleCalculPrime(props.dataForm)
        }
        if(activeStep===2 && (dataForm && dataForm.hasOwnProperty('unableyako') && dataForm.unableyako===true) ){
            setActiveStep((prevActiveStep) => prevActiveStep + 2);
        }else{
            setActiveStep((prevActiveStep) => prevActiveStep + 1);
        }
        
        containerref.current.offsetParent.scrollTop=0
    };

    const handleBack = () => {
        //setActiveStep((prevActiveStep) => prevActiveStep - 1); 
        if(activeStep===4 && (dataForm && dataForm.hasOwnProperty('unableyako') && dataForm.unableyako===true) ){
            setActiveStep((prevActiveStep) => prevActiveStep - 2); 
        }else{
            setActiveStep((prevActiveStep) => prevActiveStep - 1); 
        }
        containerref.current.offsetParent.scrollTop=0
    };

    const handleReset = () => {
        setActiveStep(0);
        containerref.current.offsetParent.scrollTop=0
    };

    const [openm, setOpenm] = React.useState(false);
    const handleClickOpen = () => {
        setOpenm(true);
    };
    const handlemClose = () => {
        setOpenm(false);
        if (succes) {
            history.push(`/${layoutRoot}/prets`, { pret: "" });
        }
    };

    const AlerteForSave = () => {
        return (
            <div>
                <Dialog
                    open={openm}
                    TransitionComponent={Transition}
                    keepMounted
                    disableBackdropClick
                    disableEscapeKeyDown
                    maxWidth="xs"
                    onClose={handlemClose}
                    aria-labelledby="alert-dialog-slide-title"
                    aria-describedby="alert-dialog-slide-description"
                >
                    <DialogContent>
                        <DialogContentText id="alert-dialog-slide-description">{
                            succes ? (<AlertSucces icon={<CheckIcon fontSize="inherit" />} severity="success">
                                <Typography variant='h4' color={green[400]}>Enregistrement effectué avec succès.</Typography> </AlertSucces>) : (
                                <AlertSucces severity="error"><Typography variant='h4' color={red[400]}>Le système a rencontré une ereur lors de l'enrégistrement.</Typography></AlertSucces>
                            )
                        }
                        </DialogContentText>
                    </DialogContent>
                    <DialogActions>
                        {succes ? (
                            <SuccesButton onClick={handlemClose} variant="contained" color="primary" className={classes.margin}>
                                OK
                            </SuccesButton>
                        ) : (
                            <ErrorButton onClick={handlemClose} variant="contained" color="primary" className={classes.margin}>
                                OK
                            </ErrorButton>
                        )}

                    </DialogActions>
                </Dialog>
            </div>
        )
    }
    const mettreEnAttente = (values) => {
        let mesDonnees
        mesDonnees = JSON.stringify(values, null, 2)
        mesDonnees = JSON.parse(mesDonnees)
        mesDonnees.questionnaires = questionnairesField.data
        mesDonnees.etat = -1
        mesDonnees.encotation = (examensup !== true) ? false : true
        mesDonnees.motifcotation = mesDonnees.hasOwnProperty('motifcotation') ? mesDonnees.motifcotation.join(";") : ""
        mesDonnees.beneficiaireaudeces = mesDonnees.hasOwnProperty('beneficiaireaudeces') ? mesDonnees.beneficiaireaudeces.join(";") : ""
        setLoading(true)
        props.savegardePret(mesDonnees).then(response => {
            if (response) {
                setSucces(true)
                setLoading(false)
                handleClickOpen()
            } else {
                setLoading(false)
                setSucces(false)
                handleClickOpen()
                return
            }
        })
    }

    const [svData, setSvData] = useState(null)
    const handleRedirectSouscription = (data) => {
        setActiveStep(0)
        dispatch(reset('addPret'))
        history.push(`/${layoutRoot}/prets`, { pret: svData });
    }
    const [topen, setTopen] = useState(false)
    const handleTOpen = () => {
        setTopen(true)
    }
    const handleTClose = () => {
        setTopen(false)
    }

    const actualizCalcul = useCallback(() => {
        if (props.hasOwnProperty('dataForm') && montantpret && duree && datenaissance) {
            handleCalculPrime(props.dataForm)
        }
    }, [montantpret, duree, datenaissance])

    useEffect(() => {
        actualizCalcul()
    }, [actualizCalcul])

    const handleAdhentConnexe =(dtInfos)=>{
        if(dataForm && dataForm.typeadherent =='Groupe'){
            if(!dataForm.hasOwnProperty('adherentconex')){
                props.dispatch(registerField("addPret",'adherentconex','Field'));
            }
            
        }
        props.updateField('adherentconex',dtInfos)
    }

    return (
        <div ref={containerref} className={classes.rootm}>
            {AlerteForSave()}
            <Snackbar open={open} autoHideDuration={6000} onClose={handleClose}>
                <Alert onClose={handleClose} severity={alertinfo.mode}>
                    {alertinfo.message}
                </Alert>
            </Snackbar>
            <div>
            <Pret>
                {(dataForm && dataForm.hasOwnProperty('mode') && dataForm.mode === 'UPDATE') ? (<Paper className={classes.hPaper} variant="outlined">
                    <div style={{display:'flex'}}>
                        <div className={classes.hContainer}>
                            <Typography variant='h5'>Mise à jour de la conation N°: {dataForm.id}</Typography>
                        </div>
                    </div>
                    <Tooltip title="Retour"> 
                        <IconButton onClick={resetForm} color='primary'>
                            <RotateLeftIcon style={{fontSize:40}} />
                        </IconButton>
                    </Tooltip>
                </Paper>) : (<Paper className={classes.hPaper} variant="outlined">
                    <div style={{display:'flex'}}>
                        <div className={classes.hContainer}>
                            <Typography variant='h5'>Nouvelle souscription</Typography>
                        </div>
                    </div>
                    <Tooltip title="Réinitalisé"> 
                        <IconButton onClick={resetForm} color='primary'>
                            <RotateLeftIcon style={{fontSize:40}} />
                        </IconButton>
                    </Tooltip>
                </Paper>)}
                
                <Pret.Base>
                    <Pret.Form>
                        {activeStep == 0 && <InfosEmprunteur handleAdhentConnexe={handleAdhentConnexe} handleAttente={mettreEnAttente} handleBack={handleBack} handleNext={handleNext} direction="row" steps={steps} activeStep={activeStep} />}
                        {activeStep == 1 && <InfosPret handleAttente={mettreEnAttente} handleBack={handleBack} handleNext={handleNext} direction="row" steps={steps} activeStep={activeStep} />}
                        {activeStep == 2 && <InfosSante handleAttente={mettreEnAttente} handleBack={handleBack} questionnairesField={questionnairesField} handleNext={handleNext} handleCalculPrime={handleCalculPrime} direction="row" steps={steps} activeStep={activeStep} />}
                        {activeStep == 3 && <InfosBeneficiaire handleAttente={mettreEnAttente} handleBack={handleBack} handleNext={handleNext} direction="row" steps={steps} activeStep={activeStep} />}
                        {activeStep == 4 && <PretFicheresume setSvData={setSvData} handleAttente={mettreEnAttente} handleBack={handleBack} questionaires={questionnairesField.data} handleNext={handleNext} direction="row" steps={steps} activeStep={activeStep} />}
                        {activeStep == 5 && <DocumentPret step={activeStep} svdata={svData} handleTOpen={handleTOpen} questionaires={questionnairesField.data} handleRedirectSouscription={handleRedirectSouscription} handleBack={handleBack} steps={steps} activeStep={activeStep} onSubmit={handleNext} btnshow={true} />}
                    </Pret.Form>
                    
                    <Pret.SideBar>
                        <Paper className={classes.paperP} elevation={3}>
                            <Pret.Group>
                                <Pret.Text variant="body" component="h6">Nature du prêt : </Pret.Text>
                                <Pret.Text variant="h6" style={{marginLeft:10}} component="h6">{`${(dataForm && dataForm.hasOwnProperty('naturepret')) ? (dataForm.naturepret =='nouvau'?'Nouveau prêt':dataForm.naturepret) : ''}`}</Pret.Text>
                            </Pret.Group>
                            <Divider />
                            <Pret.Group>
                                <Pret.Text variant="body" component="h6">Nom : </Pret.Text>
                                <Pret.Text variant="h6" style={{marginLeft:10}} component="h6">{`${(dataForm && dataForm.hasOwnProperty('nomSous')) ? dataForm.nomSous : ''}`}</Pret.Text>
                            </Pret.Group>
                            <Pret.Group>
                                <Pret.Text variant="body" component="h6">Prénom : </Pret.Text>
                                <Pret.Text variant="h6" style={{marginLeft:10}} component="h6">{`${(dataForm && dataForm.hasOwnProperty('prenomSous')) ? dataForm.prenomSous : ''}`}</Pret.Text>
                            </Pret.Group>
                            <Pret.Group>
                                <Pret.Text variant="body" component="h6">Né(e): </Pret.Text>
                                <Pret.Text variant="h6" style={{marginLeft:10}} component="h6">{`${datenaissance ? dayjs(datenaissance).format('DD/MM/YYYY') : ''}`}</Pret.Text>
                            </Pret.Group>
                            <Pret.Group>
                                <Pret.Text variant="body" component="h6">Age: </Pret.Text>
                                <Pret.Text variant="h6" style={{marginLeft:10}} component="h6">{`${datenaissance ? ageAssure(datenaissance) : ''} ans`}</Pret.Text>
                            </Pret.Group>
                            <Divider style={{marginTop:10,marginBottom:10}} />
                            <Pret.Text variant="body" component="h6">Montant du prêt :</Pret.Text>
                            <Pret.Text variant="h5" component="h5">{(montantpret, montantpret !== undefined) && formatNumber(montantpret)} XOF</Pret.Text>
                            <Pret.Group>
                                <Pret.Text variant="body" component="h6">Durée (mois) :</Pret.Text>
                                <Pret.Text variant="h5" style={{marginLeft:10}} component="h5">{`${(dataForm && dataForm.hasOwnProperty('duree')) ? dataForm.primeht && formatNumber(Math.round(dataForm.duree)) : 0}`}</Pret.Text>
                           </Pret.Group>
                           <Divider style={{marginTop:10,marginBottom:10}} />

                           {(dataForm && (dataForm.primeobseque !==0 && dataForm.unableyako===false))&&<>
                                
                            <Pret.Text variant="body" component="h6">{['PAMFCI','COFINA'].includes(credentials.codepartenaire)?"prime couverture de prêt :":"Prime décès emprunteur :"}</Pret.Text>
                                {(dataForm && dataForm.hasOwnProperty('examensup') && dataForm['examensup']==true)? null :(
                                    <Pret.Text variant="h5" component="h5">{`${(dataForm && dataForm.hasOwnProperty('primeht')) ? dataForm.primeht && formatNumber(Math.round(dataForm.primeht)) : 0}`} XOF</Pret.Text>)}
                           </>}
                           {(['PAMFCI','COFINA'].includes(credentials.codepartenaire))&&<>
                                <Pret.Text variant="body" component="h6">Prime YAKO emprunteur :</Pret.Text>
                                <Pret.Text variant="h5" component="h5">
                                    {`${(dataForm && dataForm.hasOwnProperty('primeobseque')) ? dataForm.primeobseque && ((dataForm.typeadherent==='Groupe' ? formatNumber(parseInt(dataForm.primeobseque)*parseInt(dataForm.nbadherent)):formatNumber(dataForm.primeobseque)) ) : 0}`} XOF
                                </Pret.Text>
                           </>}
                          
                            
                            {(['PAMFCI'].includes(credentials.codepartenaire))&&<>
                                <Pret.Text variant="body" component="h6">Prime de risque emprunteur PAMF CI :</Pret.Text>
                                <Pret.Text variant="h5" component="h5">{`${(dataForm && dataForm.hasOwnProperty('primerisque')) ? dataForm.primerisque && formatNumber(dataForm.primerisque) : 0}`} XOF</Pret.Text>
                            </>}
                            <Pret.Text variant="body" component="h6">Surprime:</Pret.Text>
                            {(dataForm && dataForm.hasOwnProperty('examensup') && dataForm['examensup']==true)? null:(
                                <Pret.Text variant="h5" component="h5">{`${(dataForm && dataForm.hasOwnProperty('montantsurprime')) ? dataForm.montantsurprime && formatNumber(Math.round(dataForm.montantsurprime)) : 0}`} XOF</Pret.Text>
                            )}
                            
                            
                           {(dataForm && dataForm.hasOwnProperty('fraisaccessoires') && dataForm['fraisaccessoires']!==0 && dataForm['fraisaccessoires']!==null)&&<>
                                <Pret.Text variant="body" component="h6">Frais accesoires:</Pret.Text>
                                <Pret.Text variant="h5" component="h5">{`${(dataForm && dataForm.hasOwnProperty('fraisaccessoires')) ? dataForm.fraisaccessoires && formatNumber(dataForm.fraisaccessoires) : 0}`} XOF</Pret.Text>
                            
                           </>}
                            
                            <Divider style={{marginTop:10,marginBottom:10}} />
                            <Pret.Text variant="body" component="h6">{(credentials.codepartenaire==='092') ? "Prime BNI emprunteur" :"Prime totale" } :</Pret.Text>
                            {(dataForm && dataForm.hasOwnProperty('examensup') && dataForm['examensup']==true)? null:(
                                activeStep >= 2 && <Pret.Text variant="h3" component="h3">{(!isNaN(prime) || prime !== undefined) ? formatNumber(prime) : 0} XOF</Pret.Text>
                            )}
                        </Paper>

                        <Stepper activeStep={activeStep} orientation="vertical" className={classes.paper}>
                            {steps.map((label) => (
                                <Step key={label}>
                                    <StepLabel>{label}</StepLabel>
                                </Step>
                            ))}
                        </Stepper>
                    </Pret.SideBar>
                </Pret.Base>
            </Pret>
            </div>
        </div>

    )
}
const selector = formValueSelector('addPret')
const mapStateToProps = (state) => {
    const { duree, dateEcheanceFin, datenaissance, prime, dateEcheanceDebut, questionnaires, montantpret, examensup } = selector(state, 'duree', 'datenaissance', 'prime', 'montantpret', 'dateEcheanceFin', 'dateEcheanceDebut', 'questionnaires', 'examensup')
    return {
        api: state.api,
        ui: state.UI,
        user: state.user,
        duree,
        dateEcheanceFin,
        dateEcheanceDebut,
        questionnaires,
        montantpret,
        datenaissance,
        prime,
        examensup,
        dataForm: getFormValues('addPret')(state),
        initialValues: state.form.addPret.values
    }
};
const updateField = (field, data) => (dispatch) => dispatch(change('addPret', field, data))
const mapActionsToProps = {
    updateField,
    getVille,
    getPays,
    getProfession,
    getCivilite,
    getSociete,
    savegardePret
}
export default connect(mapStateToProps, mapActionsToProps)(reduxForm({form:'addPret', destroyOnUnmount:false,})(AddPret))

const secteurActivites = [{ 'MonLibelle': 'AGENT ADMINISTRATIF' },
{ 'MonLibelle': "AGENT D'ASSURANCE / BANQUE / ETS FINANCIER" },
{ 'MonLibelle': 'ARCHITECTE' },
{ 'MonLibelle': 'ARTISAN (BOULANGER ,MENUSIER , COIFFEUR , CORDONNIER)' },
{ 'MonLibelle': 'ARTISTE (CHANTEUR , MUSICIEN , PEINTRE , DECORATEUR , CINEASTRE ,ACTEUR) ' },
{ 'MonLibelle': 'ASSISTANT( E ) DE DIRECTION (SECRETAIRE , ATTACHE DE DIRECTION, …)' },
{ 'MonLibelle': 'ASSISTANTE SOCIALE' },
{ 'MonLibelle': 'AVOCAT' },
{ 'MonLibelle': 'CADRE ADMINISTRATI F ( PRIVE )' },
{ 'MonLibelle': 'CADRE COMMERCIAL' },
{ 'MonLibelle': 'CADRE FINANCIER (PRIVE)' },
{ 'MonLibelle': 'CHIRURGIEN DENTISTE' },
{ 'MonLibelle': "CLERC DE NOTAIRE , D'HUISIER ,  D'AVOCAT" },
{ 'MonLibelle': 'COMMERCANT' },
{ 'MonLibelle': 'COMPTABLE PRIVE' },
{ 'MonLibelle': 'CONSEILLER  JURIDIQUE' },
{ 'MonLibelle': 'DELEGUE MEDICAL' },
{ 'MonLibelle': 'DIRECTEUR ADMINISTRATIF ET FINANCIER' },
{ 'MonLibelle': 'DIRECTEUR COMMERCIAL' },
{ 'MonLibelle': "DIRECTEUR D'ENTREPRISE" },
{ 'MonLibelle': 'DIRECTEUR TECHNIQUE' },
{ 'MonLibelle': 'ENSEIGNANT DU PRIMAIRE' },
{ 'MonLibelle': 'ENSEIGNANT DU SECONDAIRE' },
{ 'MonLibelle': 'ENSEIGNANT DU SUPERIEUR' },
{ 'MonLibelle': 'ENTREPRENEUR' },
{ 'MonLibelle': 'EXPERT COMPTABLE' },
{ 'MonLibelle': 'EXPLOITANT AGRICOLE OU FORESTIER' },
{ 'MonLibelle': 'FONCTIONNAIRE' },
{ 'MonLibelle': 'HAUT  FONCTIONNAIRE' },
{ 'MonLibelle': 'HUISSIER DE JUSTICE' },
{ 'MonLibelle': 'INFIRMIER' },
{ 'MonLibelle': 'INFORMATICIEN' },
{ 'MonLibelle': 'INGENIEUR' },
{ 'MonLibelle': 'JOURNALISTE' },
{ 'MonLibelle': 'M EDECIN' },
{ 'MonLibelle': 'NOTAIRE' },
{ 'MonLibelle': 'OFFICIER (POLICE , MILITAIRE , GENDARMERIE)' },
{ 'MonLibelle': 'OPTICIEN' },
{ 'MonLibelle': 'OUVRIER' },
{ 'MonLibelle': 'PHARMACIEN' },
{ 'MonLibelle': 'PUBLICITAIRE' },
{ 'MonLibelle': 'RELIGIEUX' },
{ 'MonLibelle': 'RETRAITE' },
{ 'MonLibelle': 'SAGE FEMME' },
{ 'MonLibelle': 'SALARIE ORGANISME  INTERNATIONAL' },
{ 'MonLibelle': 'SANS  PROFESSION' },
{ 'MonLibelle': 'SOUS-OFFICIER (POLICE , MILITAIRE , GENDARMERIE)' },
{ 'MonLibelle': 'TECHNICIEN (AUTO , PHOTO , TEXTIL ,MODE)' },
{ 'MonLibelle': 'TRANSPORTEUR' },
{ 'MonLibelle': 'SANTE ET BIEN ETRE ' },
{ 'MonLibelle': 'EDUCATION' },
{ 'MonLibelle': 'CONSTRUCTION' },
{ 'MonLibelle': 'SOUS-TRAITANT GRANDES ENTREPRISES' },
{ 'MonLibelle': 'AUTRES PME' }]
