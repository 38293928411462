import React, { useState } from "react";
import { reduxForm, change, getFormValues } from 'redux-form';
import { connect } from 'react-redux';
import ProdFunctions from 'pages/Producteur/ProdFonctions';
import ProdByPartner from 'pages/Producteur/ProdByPartner';


function ProductionHome(props) {
    const [fonctionnalite, setFonctionalite] = useState('HOME');
    const [currentPartenaire, setCurrentPartenaire] = useState();
    const handleCliqueFonction = (element, partenaire = null) => {
        setFonctionalite(element)
        setCurrentPartenaire(partenaire)
    }
    console.log(props);
    return (
        <>
            {fonctionnalite === 'HOME' && <ProdByPartner history={props.history} handleCliqueFonction={handleCliqueFonction} />}
            {(currentPartenaire && fonctionnalite === 'DETAIL') && <ProdFunctions history={props.history} handleCliqueFonction={handleCliqueFonction} donnee= {{partenaire:currentPartenaire}}/>}

        </>
    )
}


const updateField = (field, data) => (dispatch) => dispatch(change('adForm', field, data))
const mapPropsActions = {
    updateField,
}
const mapStateToProps = (state) => ({
    api: state.api,
    user: state.user,
    data: getFormValues('adForm')(state)
});

export default connect(mapStateToProps, mapPropsActions)(reduxForm({ destroyOnUnmount: false, })(ProductionHome))

