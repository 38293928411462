import React, { useEffect, useState } from "react";
import { makeStyles } from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
import CardActionArea from '@material-ui/core/CardActionArea';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import CardMedia from '@material-ui/core/CardMedia';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import { getPartenaire } from "redux/actions/settingsAction";
import { handlePatnerLogo } from "application";

const useStyles = makeStyles((theme) => ({
    root: {
      maxWidth: 345,
      height: '100%',
      display: 'flex',
      flexDirection: 'column',
      backgroundColor: '#f0f0f0',
      color: '#333',
      transition: 'box-shadow 0.3s',
      '&:hover': {
        boxShadow: '0px 0px 15px 0px rgba(0,0,0,0.75)',
      },
    },
    media: {
      height: 140,
      backgroundSize: 'contain',
      backgroundPosition: 'center',
      padding: theme.spacing(2),
    },
    prodByPartnerContainer: {
      display: 'flex',
      flexWrap: 'wrap',
      justifyContent: 'center',
      [theme.breakpoints.up('lg')]: {
        '& > *': {
          margin: theme.spacing(1),
          flexBasis: 'calc(25% - 20px)',
        },
      },
      [theme.breakpoints.up('md')]: {
        '& > *': {
          margin: theme.spacing(1),
          flexBasis: 'calc(50% - 20px)',
        },
      },
      [theme.breakpoints.down('sm')]: {
        flexDirection: 'column',
        alignItems: 'center',
        '& > *': {
          margin: theme.spacing(1),
          width: '100%',
        },
      },
    },
    cardContent: {
      flexGrow: 1,
      padding: theme.spacing(2),
      backgroundColor: '#fff',
    },
    cardActions: {
      padding: theme.spacing(1, 2),
      backgroundColor: '#fff',
    },
    button: {
      backgroundColor: '#ffd54f',
      color: '#333',
      '&:hover': {
        backgroundColor: '#ffee58',
      },
    },
  }));


export default function ProdByPartner(props) {

    const classes = useStyles();

    const [partenaires, setPartenaires] = useState();

    useEffect (() => {
        getPartenaire().then((result) => {
            if(result){
                setPartenaires(result);
            }
        });
    }, [])
    
    // console.log(partenaires)

  return (
    <div className={classes.prodByPartnerContainer}>
        {(partenaires && partenaires.length !== 0 )? (partenaires.map(res => (
            <Card className={classes.root}>
              <CardActionArea>
                <CardMedia
                  className={classes.media}
                  image={handlePatnerLogo(res.code)} 
                  title={`Logo ${res.designation}`}
                />
                <CardContent className={classes.cardContent}>
                  <Typography gutterBottom variant="h5" component="h2">
                    {res.designation}
                  </Typography>
                </CardContent>
              </CardActionArea>
              <CardActions className={classes.cardActions}>
                <Button size="small" className={classes.button} onClick={() => props.handleCliqueFonction("DETAIL", res)}>
                  Voir plus
                </Button>
              </CardActions>
            </Card>
        ))):(<h1>Aucun résultat ! </h1>) }
    </div>
  )
}
